import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards-carousel-ll',
  templateUrl: './cards-carousel-ll.component.html',
  styleUrls: ['./cards-carousel-ll.component.scss']
})
export class CardsCarouselLlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
