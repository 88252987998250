import { Component, OnInit } from '@angular/core';
import { Contacts } from 'src/app/interfaces/contacts';
import { PublicInfoService } from 'src/app/services/public-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private getSocial: PublicInfoService) { }

  contacts:Contacts[]

  ngOnInit(): void {
    this.getSocial.getContacts().subscribe(data => this.contacts = data)
  }

}
