<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<!-- Page banner -->
<section class="page-banner">
  <div class="container">
      <div class="page-banner-content">
          <h2>بطولات الأتحاد</h2>
      </div>
  </div>
</section>
<!-- End Page banner -->

<!-- cships cards carousel-->
<!-- <app-cards-carousel-ll></app-cards-carousel-ll> -->
<!-- end cships cards carousel-->

<!-- cships cards -->
<app-cships-cards></app-cships-cards>
<!-- end cships cards -->

  <!-- footer-->
  <app-footer></app-footer>
  <!-- /footer-->