<app-get-access></app-get-access>
<app-sidebar></app-sidebar>
<div class="ct">
  <button type="button" mdbBtn class="relative waves-light custom" (click)="basicModal.show()" mdbWavesEffect>أضافة جديد</button>
<div class="container">
    <ngb-tabset>

        <ngb-tab title="المنتخب">
          <ng-template ngbTabContent>
            <table mdbTable>
                <thead class="custom white-text">
                  <tr>
                    <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr mdbTableCol  *ngFor="let details of team; index as i">
                    <th scope="row">{{details.id}}</th>
                    <td>{{details.title}}</td>
                    <td> <img [src]="details.photoUrl" width="60" height="60"></td>
                    <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
                    <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-body" style="text-align: center;">
                            <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                            
                            <p class="pp"> هل تريد تأكيد الحزف</p>
                    
                              <div class="modal-footer">
                                
                                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                                
                                <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="deleteTeam(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tr>
                </tbody>
              </table>  
        </ng-template>
        </ngb-tab>

        <ngb-tab title="الدورات">
          <ng-template ngbTabContent>
            <table mdbTable>
                <thead class="custom white-text">
                  <tr>
                    <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr mdbTableCol  *ngFor="let details of trainings; index as i">
                    <th scope="row">{{details.id}}</th>
                    <td>{{details.title}}</td>
                    <td> <img [src]="details.photoUrl" width="60" height="60"></td>
                    <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
                    <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-body" style="text-align: center;">
                            <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                            
                            <p class="pp"> هل تريد تأكيد الحزف</p>
                    
                              <div class="modal-footer">
                                
                                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                                
                                <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="deletetraining(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tr>
                </tbody>
              </table>  
        </ng-template>
        </ngb-tab>

        <ngb-tab title="البطولات">
            <ng-template ngbTabContent>
              <table mdbTable>
                  <thead class="custom white-text">
                    <tr>
                      <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr mdbTableCol  *ngFor="let details of cships; index as i">
                      <th scope="row">{{details.id}}</th>
                      <td>{{details.title}}</td>
                      <td> <img [src]="details.photoUrl" width="60" height="60"></td>
                      <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
                      <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-body" style="text-align: center;">
                              <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                              
                              <p class="pp"> هل تريد تأكيد الحزف</p>
                      
                                <div class="modal-footer">
                                  
                                  <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                                  
                                  <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="deletecship(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tr>
                  </tbody>
                </table>  
          </ng-template>
        </ngb-tab>
        
        <ngb-tab title="اللاعبين">
            <ng-template ngbTabContent>
              <table mdbTable>
                  <thead class="custom white-text">
                    <tr>
                      <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr mdbTableCol  *ngFor="let details of players; index as i">
                      <th scope="row">{{details.id}}</th>
                      <td>{{details.title}}</td>
                      <td> <img [src]="details.photoUrl" width="60" height="60"></td>
                      <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
                      <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-body" style="text-align: center;">
                              <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                              
                              <p class="pp"> هل تريد تأكيد الحزف</p>
                      
                                <div class="modal-footer">
                                  
                                  <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                                  
                                  <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="deleteplayer(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tr>
                  </tbody>
                </table>  
          </ng-template>
        </ngb-tab>
          
    </ngb-tabset>


    </div>
    <div class="ct2"></div>
</div>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        
        <ngb-tabset>

            <ngb-tab title="المنتخب" class="ngb-tab">
              <ng-template ngbTabContent>
                <form #f="ngForm" (ngSubmit)="addNewTeam(f)">
        
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="form1" class="form-control cc" ngModel name='title' required> 
                  
                      <label for="form1" class="label">أسم الصورة </label>
                  
                    </div>
          
                    <div class="button-wrapper">
          
                      <span class="label">
                        أختر الملف
                      </span>
                      
                        <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="image" #image1 required>
                      
                    </div>
                              
                    <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 35.1%;" id="loadingAlert1">
                    
                      

                    <div class="alert alert-success" role="alert" style="display: none;" id="alert1">
                      تم التحميل بنجاح
                    </div>
          
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                      يوجد خطأ ما!الرجاء المحاولة مرة أخري
                     <br>
                      {{errorMessage}}
                    </div>

                    <div class="modal-footer">
                      
                      <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                      
                      <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f.invalid || submit == false" style="border-radius: 10px;">
                    
                    </div>
          
              </form>
              </ng-template>
            </ngb-tab>

            <ngb-tab title="الدورات" class="ngb-tab">
              <ng-template ngbTabContent>
                <form #f2="ngForm" (ngSubmit)="addNewTraining(f2)">
        
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="form1" class="form-control cc" ngModel name='title' required> 
                  
                      <label for="form1" class="label">أسم الصورة </label>
                  
                    </div>
          
                    <div class="button-wrapper">
          
                      <span class="label">
                        أختر الملف
                      </span>
                      
                        <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="image2" #image2 required>
                      
                    </div>
                              
                    <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 35.1%;" id="loadingAlert2">
                    
                      

                    <div class="alert alert-success" role="alert" style="display: none;" id="alert2">
                      تم التحميل بنجاح
                    </div>
          
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                      يوجد خطأ ما!الرجاء المحاولة مرة أخري
                     <br>
                      {{errorMessage}}
                    </div>

                    <div class="modal-footer">
                      
                      <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                      
                      <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f2.invalid || submit == false" style="border-radius: 10px;">
                    
                    </div>
          
              </form>
              </ng-template>
            </ngb-tab>

            <ngb-tab title="البطولات" class="ngb-tab">
                <ng-template ngbTabContent>
                  <form #f3="ngForm" (ngSubmit)="addNewCship(f3)">
          
                      <div class="md-form">
                    
                        <input mdbInput type="text" id="form1" class="form-control cc" ngModel name='title' required> 
                    
                        <label for="form1" class="label">أسم الصورة </label>
                    
                      </div>
            
                      <div class="button-wrapper">
            
                        <span class="label">
                          أختر الملف
                        </span>
                        
                          <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="image3" #image3 required>
                        
                      </div>
                                
                      <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 35.1%;" id="loadingAlert3">
                    
                      

                      <div class="alert alert-success" role="alert" style="display: none;" id="alert3">
                        تم التحميل بنجاح
                      </div>
      
                      <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                        يوجد خطأ ما!الرجاء المحاولة مرة أخري
                       <br>
                        {{errorMessage}}
                      </div>

                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
                        <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f3.invalid || submit == false " style="border-radius: 10px;">
                      
                      </div>
            
                </form>
                </ng-template>
              </ngb-tab>

              <ngb-tab title="اللاعبين" class="ngb-tab">
                <ng-template ngbTabContent>
                  <form #f4="ngForm" (ngSubmit)="addNewPlayer(f4)">
          
                      <div class="md-form">
                    
                        <input mdbInput type="text" id="form1" class="form-control cc" ngModel name='title' required> 
                    
                        <label for="form1" class="label">أسم الصورة </label>
                    
                      </div>
            
                      <div class="button-wrapper">
            
                        <span class="label">
                          أختر الملف
                        </span>
                        
                          <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="image4" #image4 required>
                        
                      </div>
                                
                      <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 35.1%;" id="loadingAlert4">
                                
                                  

                      <div class="alert alert-success" role="alert" style="display: none;" id="alert4">
                        تم التحميل بنجاح
                      </div>
            
                      <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                        يوجد خطأ ما!الرجاء المحاولة مرة أخري
                       <br>
                        {{errorMessage}}
                      </div>
                      
                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
                        <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f4.invalid || submit == false" style="border-radius: 10px;">
                      
                      </div>
            
                </form>
                </ng-template>
              </ngb-tab>

          </ngb-tabset>
      </div>
    </div>
  </div>
</div>