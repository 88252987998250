

<body>
  <!-- Views-->
  <router-outlet></router-outlet>
  <!-- /Views-->

  <!-- offline layer -->
  <div class="offline-layer" *ngIf="showOnlineLayer == true">
    <p>يوجد خطأ في الشبكة, الرجاء المحاولة مرة أخري</p>
    <a>
          <mdb-icon fas icon="redo-alt" (click)="refresh()"></mdb-icon>
    </a>
  </div>
  <!-- offline layer -->
</body>


