import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewsService } from 'src/app/services/news.service';
import { News } from 'src/app/interfaces/news.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-news-cards',
  templateUrl: './news-cards.component.html',
  styleUrls: ['./news-cards.component.scss']
})
export class NewsCardsComponent implements OnInit,OnDestroy {

  new: News[] = []
  newsObservable:Subscription;

  constructor(private newss: NewsService) { }

  ngOnInit() {
    this.newsObservable = this.newss.getNews().subscribe(data => {
      this.new = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }

  ngOnDestroy() {
    this.newsObservable.unsubscribe();
  }
}
