import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class JoinusService {

  constructor(private fs: AngularFirestore) { }

  getJoin(){ return this.fs.collection('joinUs').snapshotChanges() }

  deleteJoin(id){return this.fs.doc(`joinUs/${id}`).delete()}
  
  addJoin(
    name:string,
    email:string,
    message:string,
    phone:string,
  ){
  return new Promise((resolve, reject)=> {
    
      this.fs.collection('joinUs').add({
        name,
        email,
        message,
        phone
      }).then(()=>resolve())
    })
  }
}
