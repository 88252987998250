<app-navbar></app-navbar>
<!-- Page banner -->
<section class="page-banner">
    <div class="container">
        <div class="page-banner-content">
            <h2> الدورات المتوفرة للمبتدأين</h2>
        </div>
    </div>
  </section>
  <!-- End Page banner -->
  
  <!--latest news cards-->
  <app-bcards></app-bcards>
  <!--/latest news cards-->
  
    <!-- footer-->
    <app-footer></app-footer>
    <!-- /footer-->