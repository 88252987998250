<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<!-- Page banner -->
  <section class="page-banner">
    <div class="container">
        <div class="page-banner-content">
            <h2> عن الأتحاد  </h2>
        </div>
    </div>
  </section>
<!-- End Page banner -->
<!-- Start About Section -->
<section class="about-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-img-wrapper">
                    <div class="about-img-1">
                        <img src="assets/imgs/news-3.png" alt=""width="350">
                    </div>
                    
                    <div class="about-img-2">
                        <img src="assets/imgs/news-2.png" alt="" width="450">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <h2>الأتحاد السعودي للجودو</h2>
                    <p *ngFor="let info of presentaition">
                        {{info.message}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Section -->

<!-- Start Mission Section -->
<section class="mission-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="mission-area">
                    <div class="mission-content">
                        <h3>هدف الأتحاد</h3>
                        <p *ngFor="let info of goal">
                            {{info.message}}
                        </p>
                    </div>
                </div>    
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="mission-inage">
                    <img src="assets/imgs/msg.png" alt="image" class="just-another-img">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Mission Section -->

<!--founders section-->
<app-parallax-section></app-parallax-section>
<!--/founders section-->

<div style="margin-top: 40px;">
 <!--explore section-->
<app-contacts ></app-contacts>
<!--/explore section-->   
</div>

  <!-- footer-->
  <app-footer></app-footer>
  <!-- /footer-->
