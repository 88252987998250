import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { error } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})
export class VideosService {

  constructor(private fs: AngularFirestore, private storage: AngularFireStorage) { }

  getVideos(){ return this.fs.collection('videos').valueChanges() }

  getVideosId(){ return this.fs.collection('videos').snapshotChanges() }

  addVideos(title:string,videoUrll:File,videoImgg:File)
     {
       return new Promise((resolve, reject)=> {
      
         let ref1 = this.storage.ref('videos/' + videoUrll.name)
         let ref2 = this.storage.ref('videoImg/' + videoImgg.name)

         ref1.put(videoUrll),
         ref2.put(videoImgg).then( ()=> {
           ref1.getDownloadURL().subscribe(videoUrl => {
             ref2.getDownloadURL().subscribe(videoImg => {
              this.fs.collection('videos').add({
              title,
               videoUrl,
               videoImg
            })
           })
         })
       }).then(()=>resolve())
     })
  }
  delete(id){ return this.fs.doc(`videos/${id}`).delete() }
}
