<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<!-- Page banner -->
<section class="page-banner-single" style="padding-top: 75px;">
    <div mdbModal #basicModal="mdbModal" style="overflow-y: auto" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-body">
              
              <form #f="ngForm" (ngSubmit)="book(f)">
              
                <div class="md-form">
              
                  <input mdbInput type="text" id="name" class="form-control cc" ngModel name='name' required> 
              
                  <label for="name" class="label">الأسم كامل  </label>
              
                </div>
                <div class="md-form">
              
                  <input mdbInput type="text" id="phone" class="form-control cc" ngModel name='phone' required> 
              
                  <label for="phone" class="label">رقم الهاتف </label>
              
                </div>      
                <div class="md-form">
              
                  <input mdbInput type="email" id="email" class="form-control cc" ngModel name='email' required> 
              
                  <label for="email" class="label">البريد الألكتروني  </label>
              
                </div>
                <div class="md-form">
              
                    <input mdbInput type="text" id="trainingTitle" class="form-control cc" [(ngModel)]="trainingTitle" name='trainingTitle' required> 
                
                    <label for="email" class="trainingTitle">الرجاء أسم نسخ أسم الدورة هنا  </label>
                
                </div>
                <div class="md-form">
                    
                  <textarea type="text" id="otherDetails" class="md-textarea form-control cc2" ngModel name='otherDetails' mdbInput></textarea>
                  
                  <label for="otherDetails" class="cc"> أكتب رسالتك</label>
              
              </div>
      
              <img src="assets/imgs/loading2.gif" class="ld-gif" *ngIf="loading == true">
                
                <div style="text-align: center;" *ngIf="done == true">
                  <p style="color: #32c671;"> 
                       تم  الأرسال, سيتم التواصل معك في أقرب وقت
                  </p>
                  <img src="assets/imgs/confirm.png" width="50">
                </div>
      
                <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                    يوجد خطأ ما!الرجاء المحاولة مرة أخري
                   <br>
                    {{errorMessage}}
                  </div>

                <div class="modal-footer">
                  
                  <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                  
                  <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f.invalid || submit == false" style="border-radius: 10px;">
                
                </div>
      
          </form>
            </div>
          </div>
        </div>
      </div>

    <div class="container">
      <div class="page-banner-content">
          معلومات الدورة
      </div>
    </div>
  </section>
  <!-- End Page banner -->
  
  <!-- Start Single News Section -->
  <section class="single-news-section">
    <div class="container">
      <div class="row">

        <div class="col-lg-12 col-md-12">
            <div class="news-details">
              <div class="article-img">
                <img [src]="photoUrl" alt="blog-details">
              </div>
            </div>
            <div class="title">
                <h3>{{title}}</h3>
            </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="sidebar mt-0">
            <div class="container">
                <div class="row">

                    <div class="col-md-6">
                        <div class="widget widget-recent-entries height">
                            <h3 class="widget-title">
                                <span>تفاصيل أخري</span>
                            </h3>
                            <div class="notes">
                                <p>
                                    {{otherDetails}}
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="widget widget-recent-entries info-basic">
                            <h3 class="widget-title">
                                <span>المعلومات الأساسية</span>
                            </h3>
                            <ul>
                                <li>
                                    <img src="assets/imgs/appointment.png" width="25">
                                    <p class="date">التاريخ</p>
                                    <h5>{{date}}</h5>
                                </li>
                        
                                <li>
                                    <img src="assets/imgs/kimono.png" width="25">
                                    <p class="date"> السن</p>
                                    <h5>{{age}}</h5>
                                </li>
                        
                                <li>
                                    <img src="assets/imgs/place.png" width="15">
                                    <p class="date"> الموقع</p>
                                    <h5>{{location}}</h5>
                                </li>
                                <li>
                                    <img src="assets/imgs/money.png" width="15">
                                    <p class="date"> السعر</p>
                                    <h5><span>ريال سعودي</span>  / <span>{{price}}</span></h5>
                                </li>
                                <li>
                                    <img src="assets/imgs/clock.png" width="15">
                                    <p class="date"> المدة</p>
                                    <h5> {{period}}</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="widget widget-recent-entries entries-p">
                            <h3 class="widget-title">
                                <span>تفاصيل عن المدرب</span>
                            </h3>
                            <p class="coach-name">{{coachName}}</p>
                            <div class="notes"> 
                                <p>{{coachDetails}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="widget widget-recent-entries entries-p">
                            <h3 class="widget-title">
                                <span>حجز الدورة</span>
                            </h3>
                            <button class="full-info-btn" (click)="basicModal.show()" mdbWavesEffect>ملئ البيانات</button>
                        </div>
                    </div>

                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End Single News Section -->

  <!-- footer-->
  <app-footer></app-footer>
  <!-- /footer-->