<div class="counter-section">
  <div class="container">
    <div class="row">
      <div class="col-md-2 col-sm-12 col-xs-12">
        <i class="fas fa-medal"></i>
        <animated-digit [digit]="54" [duration]="10000"></animated-digit>
        <p class="medal-p">ميدالية</p>
      </div>
      <div class="col-md-2 col-sm-12 col-xs-12">
        <i class="fas fa-trophy"></i>
        <animated-digit [digit]="50" [duration]="10000"></animated-digit>
        <p class="cship-p">بطولة</p>
      </div>
      <div class="col-md-2 col-sm-12 col-xs-12">
        <i class="fas fa-users"></i>
        <animated-digit [digit]="86" [duration]="10000"></animated-digit>
        <p class="player-p">لاعبين</p>
      </div>
      <div class="col-md-2 col-sm-12 col-xs-12">
        <i class="fas fa-user"></i>
        <animated-digit [digit]="36" [duration]="10000"></animated-digit>
        <p class="coach-p">مدرب</p>
      </div>
      <div class="col-md-2 col-sm-12 col-xs-12">
        <i class="fas fa-user"></i>
        <animated-digit [digit]="52" [duration]="10000"></animated-digit>
        <p class="ref-p">حكم</p>
      </div>
    </div>
  </div>
</div>
