import { Component, OnInit, Host } from '@angular/core';
import { LoginGuardService } from 'src/app/services/login-guard.service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { DbAuthService } from 'src/app/services/db-auth.service';
import { NgForm } from '@angular/forms';
import { Access } from 'src/app/interfaces/access';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private loginGS:LoginGuardService, private router:Router, private dbAccess:DbAuthService) { }

  ngOnInit(): void {}

  accessArray:Access[] = []

  errr:string = ''

  submit:boolean = true

  loading:boolean = false

  access(f){
    
    this.submit = false

    this.loading = true

    let data = f.value
    this.dbAccess.login(data.email, data.password)
    .catch(err =>{
      this.errr = err.message
    })
  }

}
