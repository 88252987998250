import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { from, fromEvent } from 'rxjs';
import { Contacts } from 'src/app/interfaces/contacts';
import { Message } from 'src/app/interfaces/message';
import { PublicInfoService } from 'src/app/services/public-info.service';

@Component({
  selector: 'app-public-info',
  templateUrl: './public-info.component.html',
  styleUrls: ['./public-info.component.scss'],
  providers: [NgbTabsetConfig] // add NgbTabsetConfig to the component providers

})
export class PublicInfoComponent implements OnInit {

  constructor(config: NgbTabsetConfig, private publicInfo:PublicInfoService) {
    config.justify = 'center';
    config.type = 'pills';
   }

   contacts:Contacts[]
   
   goal:Message[]

   presentation:Message[]

   message:Message[]

  ngOnInit(): void {


    //contacts
    this.publicInfo.getContactsId().subscribe(data => {
      this.contacts = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    //goal
    this.publicInfo.getGoalId().subscribe(data => {
      this.goal = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    //presentation
    this.publicInfo.getPresentationId().subscribe(data => {
      this.presentation = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    //message
    this.publicInfo.getMessageId().subscribe(data => {
      this.message = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }
  
  doneAlert:boolean = false
  loading:boolean = false
  submit:boolean = false
   
  onFocus() {
    this.submit = true
  }


  editContacts(id:string,
    whatsappUrl:string,
    facebookUrl:string,
    youtubeUrl:string,
    snapChatUrl:string,
    twitterUrl:string,
    phoneNumber:string,
    email:string,
    location:string
    ) {
      this.submit = false
      this.loading = true
      this.publicInfo.editContacts(id,whatsappUrl,facebookUrl,youtubeUrl,snapChatUrl,twitterUrl,phoneNumber,email,location)
      .then(()=>{
        this.loading = false
        this.doneAlert = true
        setTimeout(() => {
          this.doneAlert = false
        }, 5000);
      })
  }

  editMessage(id:string, message:string) {
    this.submit = false
    this.loading = true
    this.publicInfo.editMessage(id,message)
    .then(()=>{
      this.loading = false
      this.doneAlert = true
      setTimeout(() => {
        this.doneAlert = false
      }, 5000);
    })
  }

  editGoal(id:string, message:string) {
    this.submit = false
    this.loading = true
    this.publicInfo.editGoal(id,message)
    .then(()=>{
      this.loading = false
      this.doneAlert = true
      setTimeout(() => {
        this.doneAlert = false
      }, 5000);
    })
  }

  editAbout(id:string, message:string) {
    this.submit = false
    this.loading = true
    this.publicInfo.editPresentation(id,message)
    .then(()=>{
      this.loading = false
      this.doneAlert = true
      setTimeout(() => {
        this.doneAlert = false
      }, 5000);
    })
  }
}
