import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { joinForm } from 'src/app/interfaces/join.interface';
import { JoinusService } from 'src/app/services/joinus.service';
// import * as $ from 'jquery'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  constructor(private join: JoinusService) { }

  joinForm: joinForm[] =[]

  addNew(f:NgForm){
    
    document.getElementById('loadingAlert').style.display="block";

    let name = (<joinForm>f.value).name,

        phone = (<joinForm>f.value).phone,

        email = (<joinForm>f.value).email,

        message = (<joinForm>f.value).message;

       this.join.addJoin(name,phone,email,message).then(()=> {

        document.getElementById('loadingAlert').style.display="none";
        
        document.getElementById('alert').style.display="block";
       
      })
  }
}
