import { Component,HostListener,OnInit } from '@angular/core';
import {trigger, transition, useAnimation} from "@angular/animations";
import {rotateCubeToLeft, moveFromBottomFade, fromRightEasing,slide} from "ngx-router-animations";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animations: [
  //   trigger('moveFromBottomFade', [ transition('* => *', useAnimation(moveFromBottomFade))])
  // ]
})
export class AppComponent {
  title = 'mdb-angular-free';
  
  showOnlineLayer:boolean = false
  
  isOffline:boolean

  @HostListener('window:offline', ['$event'])
  
  isWindowOffline($event){
      if($event.type =="offline") {
          this.showOnlineLayer = true
          console.log($event)
    }
  } 
  
  refresh(): void {
    window.location.reload();
  }

  
}
