import { Component, OnInit, OnDestroy } from '@angular/core';
import { TrainingsService } from 'src/app/services/trainings.service';
import { Training } from 'src/app/interfaces/trainings.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ocards',
  templateUrl: './ocards.component.html',
  styleUrls: ['./ocards.component.scss']
})
export class OcardsComponent implements OnInit {

  training: Training[] = []
  trainingsObservable:Subscription;

  constructor(private trainings: TrainingsService) { }

  ngOnInit() {
    this.trainingsObservable = this.trainings.getOldTrain().subscribe(data => {
      this.training = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }

  ngOnDestroy() {
    this.trainingsObservable.unsubscribe();
  }

}
