
      <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark is-sticky" 
                  style="position: fixed; width: 100%; z-index: 1040; background-color: white;">
        <mdb-navbar-brand>
              <a class="nav-link join-us-hover join-us" (click)="basicModal.show()" mdbWavesEffect>أنضم ألينا</a>
        </mdb-navbar-brand>
        <links>
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">عن الاتحاد</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/trainings" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">الدورات</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/championships" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">البطولات</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/news" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">الأخبار</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">الرئيسية</a>
                </li>
            </ul>
            <span class="navbar-brand">
              <span>الأتحاد السعودي للجودو</span><img src="assets/imgs/logo.png" alt="شعار الأتحاد"></span>
        </links>
    </mdb-navbar>

      <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              
              <form #f="ngForm" (ngSubmit)="addNew(f)">
              
                <div class="md-form">
              
                  <input mdbInput type="text" id="name" class="form-control cc" ngModel name='name' required> 
              
                  <label for="name" class="label">الأسم كامل  </label>
              
                </div>
                <div class="md-form">
              
                  <input mdbInput type="text" id="phone" class="form-control cc" ngModel name='phone' required> 
              
                  <label for="phone" class="label">رقم الهاتف </label>
              
                </div>      
                <div class="md-form">
              
                  <input mdbInput type="email" id="email" class="form-control cc" ngModel name='email' required> 
              
                  <label for="email" class="label">البريد الألكتروني  </label>
              
                </div>
                <div class="md-form">
                    
                  <textarea type="text" id="message" class="md-textarea form-control cc2" ngModel name='message' mdbInput required></textarea>
                  
                  <label for="otherDetails" class="cc"> أكتب رسالتك</label>
              
              </div>
      
              <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 30.5%;" id="loadingAlert">
                
                <div style="display: none; text-align: center;" id="alert">
                  <p style="color: #37b349;"> 
                       تم  الأرسال, سيتم التواصل معك في أقرب وقت
                  </p>
                  <img src="assets/imgs/confirm.gif" width="100">
                </div>
      
                <div class="modal-footer">
                  
                  <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                  
                  <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f.invalid" style="border-radius: 10px;">
                
                </div>
      
          </form>
            </div>
          </div>
        </div>
      </div>
      <!-- End Navbar Area -->
