<div class="container">
    <div class="blog-title">
      <!-- <h2>كل الأخبار</h2> -->
    </div>
    <div class="row">
      
    <div class="col-md-4" *ngFor="let details of training;">
        <div class="single-blog-post">
          <figure>
              <a [routerLink]="['/single-training', details.id]">
                  <img [src]="details.photoUrl" alt="blog-img" height="240">
              </a>
          </figure>
  
          <div class="blog-post-content">
              <span>{{details.date}}</span>
              <h4 class="card-title">{{details.title}}</h4>
              <div class="blog-btn">
                  <a class="default-btn-one" [routerLink]="['/single-training', details.id]">التفاصيل</a>
              </div>
          </div>
      </div>
    </div>

    </div>
  </div>
  