<div class="container" style="margin-top: 70px;">
  <div class="blog-title">
    <h2>كل الأخبار</h2>
  </div>
  <div class="row">
    <div class="col-md-4" *ngFor="let head of new">
      <div class="single-blog-post">
        <figure>
            <a [routerLink]="['/single-news', head.id]">
                <img [src]="head.photoUrl"  alt="blog-img" height="240">
            </a>
        </figure>

        <div class="blog-post-content">
           <span>{{head.date}}</span>
            <h2 class="card-title">{{head.title}}</h2>
            <div class="blog-btn">
                <a class="default-btn-one" [routerLink]="['/single-news', head.id]">التفاصيل</a>
            </div>
        </div>
        </div>
    </div>
  </div>
</div>
