<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<!-- Page banner -->
<section class="page-banner">
    <div class="container">
        <div class="page-banner-content">
            <h2>فيدوهات الأتحاد</h2>
        </div>
    </div>
</section>
<!-- End Page banner -->

<!--latest videos section-->
<app-videos-section></app-videos-section>
<!--/latest videos section-->

  <!-- footer-->
  <app-footer></app-footer>
  <!-- /footer-->