import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewsService } from 'src/app/services/news.service';
import { News } from 'src/app/interfaces/news.interface';
import { fromEvent, interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-cards-carousel',
  templateUrl: './cards-carousel.component.html',
  styleUrls: ['./cards-carousel.component.scss']
})
export class CardsCarouselComponent implements OnInit,OnDestroy {

  new: News[] = []

  newsObservable:Subscription;

  constructor(private newss: NewsService) { }

  ngOnInit() {
    this.newsObservable = this.newss.getNews().subscribe(data => { 
      this.new = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {},
        }
      })
    })

  }

  ngOnDestroy() {
    this.newsObservable.unsubscribe();
  }
}
