import { Component, OnInit } from '@angular/core';
import { Message } from 'src/app/interfaces/message';
import { PublicInfoService } from 'src/app/services/public-info.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(private getMessage:PublicInfoService) { }

  message:Message[]

  ngOnInit(): void {
    this.getMessage.getMessage().subscribe(data => this.message = data)
  }

}
