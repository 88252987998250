<div class="carousel-container carousel-innerr" style="position: relative; top:78px;">
  <mdb-carousel [animation]="'slide'">
  <mdb-carousel-item *ngFor="let carousel of carousels; index as i">
    <img class="d-block w-100" [src]="carousel.photoUrl">
    <div class="carousel-caption">
      <h2 class="h3-responsive">
       {{carousel.name}}
      </h2>
    </div>
  </mdb-carousel-item>
</mdb-carousel>
</div>

