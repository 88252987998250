<app-get-access></app-get-access>
<app-sidebar></app-sidebar>
<div class="ct">
  <button type="button" mdbBtn class="relative waves-light custom" (click)="basicModal.show()" mdbWavesEffect>أضافة جديد</button>
<div class="container">
    <table mdbTable>
        <thead class="custom white-text">
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{head}} </th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol  *ngFor="let details of data; index as i">
            <!-- <th scope="row"></th> -->
            <td>{{details.title}}</td>
            <td> <img [src]="details.videoImg" width="60" height="60"></td>
            <td>
              <video id="video1" width="60" height="60" autoplay>
                <source [src]="details.videoUrl">
              </video>
            </td>
            <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
            <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-body" style="text-align: center;">
                    <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                    
                    <p class="pp"> هل تريد تأكيد الحزف</p>
            
                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
                        <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="delete(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </tr>
        </tbody>
      </table>  
    </div>
    <div class="ct2"></div>
</div>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        
        <form #f="ngForm" (ngSubmit)="addNew(f)">
        
          <div class="md-form">
        
            <input mdbInput type="text" id="form1" class="form-control cc" ngModel name='title' required> 
        
            <label for="form1" class="label">عنوان المقطع  </label>
        
          </div>

          <div class="button-wrapper">

            <span class="label">
              أختر المقطع
            </span>
            
              <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="video" #video required>
            
          </div>
                    
          <div class="button-wrapper">

            <span class="label">
               صورة المقطع
            </span>
            
              <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="image" #image required>
            
          </div>

          <img src="assets/imgs/loading.gif" width="165" height="120" style="margin: 0px 35.1%;" *ngIf="loading == true">
                    
          <div class="alert alert-success" role="alert" *ngIf="done == true">
            تم التحميل بنجاح
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="thereIsError == true ">
            يوجد خطأ ما!الرجاء المحاولة مرة أخري
           <br>
            {{errorMessage}}
          </div>

          <div class="modal-footer">
            
            <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
            
            <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f.invalid || submit == false" style="border-radius: 10px;">
          
          </div>

    </form>
      </div>
    </div>
  </div>
</div>