import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Carousel } from 'src/app/interfaces/carousel.interface';
import { CarouselService } from 'src/app/services/carousel.service';

@Component({
  selector: 'app-add-carousel',
  templateUrl: './add-carousel.component.html',
  styleUrls: ['./add-carousel.component.scss']
})
export class AddCarouselComponent implements OnInit {

  @ViewChild('image') image:ElementRef

  data: Carousel[] = []

  errorMessage:string = ''

  headElements = ['ID', 'الاسم', 'الصورة', ''];
  
  constructor(private addCarousel: CarouselService) { }

  ngOnInit(): void {
     this.addCarousel.getCarouselId().subscribe(data => {
      this.data = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }

  loading: boolean = false

  done:boolean = false

  submit:boolean = true

  addNew(f: NgForm) {

    this.loading = true

    this.submit = false

    let name = (<Carousel>f.value).name,

        image = (<HTMLInputElement>this.image.nativeElement).files[0];

      this.addCarousel.addCarousel(name,image).then(()=>{
         this.loading = false
         this.done = true
         setTimeout(() => {
           this.done = false
           this.submit = true
         }, 4000);
       })
       .catch(error =>{
         this.errorMessage = error.message
       })
    }
  
    delete(index){ this.addCarousel.delete(this.data[index].id) }
        
}
