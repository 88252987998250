<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<!-- Page banner -->
<section class="page-banner">
    <div class="container">
        <div class="page-banner-content">
            <h2>أستديو الأتحاد</h2>
        </div>
    </div>
</section>
<!-- End Page banner -->

<!--gallery section-->
<app-lightbox-gallery></app-lightbox-gallery>
<!--/gallery section-->

  <!-- footer-->
  <app-footer></app-footer>
  <!-- /footer-->