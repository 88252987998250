<div class="container">
  <div class="row">
    <div class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h1 class="gallery-title">صور الاتحاد</h1>
      <p>لكل صورة قصة وحكاية خاصة</p>
      <div style="text-align: center;">
        <button class="btn filter-button nteam-btn" data-filter="nteam">المنتخب</button>
        <button class="btn filter-button players-btn" data-filter="players">الاعبين</button>
        <button class="btn filter-button trainings-btn" data-filter="trainings">الدورات</button>
        <button class="btn filter-button cships-btn" data-filter="cships">البطولات</button>
        <button class="btn filter-button all" data-filter="all">الكل</button>
      </div>
    </div>
    <br>

  <div class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter cships" *ngFor="let details of cship;">
      <div class="open-img" style="display: none;">
        <a (click)="basicModal.show()">
          <mdb-icon fas icon="search-plus"></mdb-icon>
        </a>
        <p>{{details.title}}</p>
      </div>
      <img [src]="details.photoUrl" class="img-responsive" width="365" height="450">

      <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body" style="text-align:center">
            <h4 class="modal-title" id="myModalLabel">عرض الصورة</h4>
            <img [src]="details.photoUrl" class="img-responsive" style="max-width: 685px; max-height: 525px;">
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter trainings" *ngFor="let details of training;">
      <div class="open-img" style="display: none;">
        <a (click)="basicModal.show()">
          <mdb-icon fas icon="search-plus"></mdb-icon>
        </a>
        <p>{{details.title}}</p>
      </div>
      <img [src]="details.photoUrl" class="img-responsive" width="365" height="450">

      <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body" style="text-align:center">
            <h4 class="modal-title" id="myModalLabel">عرض الصورة</h4>
            <img [src]="details.photoUrl" class="img-responsive" style="max-width: 685px; max-height: 525px;">
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter players" *ngFor="let details of player;">
      <div class="open-img" style="display: none;">
        <a (click)="basicModal.show()">
          <mdb-icon fas icon="search-plus"></mdb-icon>
        </a>
        <p>{{details.title}}</p>
      </div>
      <img [src]="details.photoUrl" class="img-responsive" width="365" height="450">

      <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body" style="text-align:center">
            <h4 class="modal-title" id="myModalLabel">عرض الصورة</h4>
            <img [src]="details.photoUrl" class="img-responsive" style="max-width: 685px; max-height: 525px;">
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter nteam" *ngFor="let details of team;">
      <div class="open-img" style="display: none;">
        <a (click)="basicModal.show()">
          <mdb-icon fas icon="search-plus"></mdb-icon>
        </a>
        <p>{{details.title}}</p>
      </div>
      <img [src]="details.photoUrl" class="img-responsive" width="365" height="450">

      <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body" style="text-align:center">
            <h4 class="modal-title" id="myModalLabel">عرض الصورة</h4>
            <img [src]="details.photoUrl" class="img-responsive" style="max-width: 685px; max-height: 525px;">
          </div>
        </div>
      </div>
    </div>

  </div>

  </div>
</div>

