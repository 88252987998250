<app-get-access></app-get-access>
<app-sidebar></app-sidebar>
<div class="ct">
  <div class="container">
      <ngb-tabset>
        
          <ngb-tab title="التواصل">
            <ng-template ngbTabContent>
                <div class="contacts-fields" *ngFor="let info of contacts">
          
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="whatsappUrl" class="form-control cc" (focus)="onFocus()" [(ngModel)]="info.whatsappUrl"> 
                  
                      <label for="whatsappUrl" class="label">واتساب </label>
                  
                    </div>
          
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="facebookUrl" class="form-control cc" (focus)="onFocus()"  [(ngModel)]="info.facebookUrl"> 
                  
                      <label for="facebookUrl" class="label">فيسبوك </label>
                  
                    </div>

                    <div class="md-form">
                
                      <input mdbInput type="text" id="youtubeUrl" class="form-control cc" (focus)="onFocus()"  [(ngModel)]="info.youtubeUrl"> 
                  
                      <label for="youtubeUrl" class="label">يوتيوب</label>
                  
                    </div>
                  
                    <div class="md-form">
                
                        <input mdbInput type="text" id="snapChatUrl" class="form-control cc" (focus)="onFocus()"  [(ngModel)]="info.snapChatUrl"> 
                    
                        <label for="snapChatUrl" class="label">سناب شات</label>
                    
                    </div>

                    <div class="md-form">
                
                        <input mdbInput type="text" id="twitterUrl" class="form-control cc" (focus)="onFocus()" [(ngModel)]="info.twitterUrl"> 
                    
                        <label for="twitterUrl" class="label"> تويتر</label>
                    
                    </div>

                    <div class="md-form">
                
                        <input mdbInput type="text" id="phoneNumber" class="form-control cc" (focus)="onFocus()" [(ngModel)]="info.phoneNumber"> 
                    
                        <label for="phoneNumber" class="label"> رقم الهاتف</label>
                    
                    </div>

                    <div class="md-form">
                
                        <input mdbInput type="text" id="email" class="form-control cc" (focus)="onFocus()" [(ngModel)]="info.email"> 
                    
                        <label for="email" class="label"> البريد الالكتروني</label>
                    
                    </div>

                    <div class="md-form">
                
                        <input mdbInput type="text" id="location" class="form-control cc" (focus)="onFocus()" [(ngModel)]="info.location"> 
                    
                        <label for="location" class="label"> العنوان</label>
                    
                    </div>

                    <img src="assets/imgs/loading.gif" width="165" height="120" style="margin: 0px 35.1%;" *ngIf="loading == true">
                  
                    <div class="alert alert-success" role="alert" *ngIf="doneAlert == true">تم التحميل بنجاح</div>
                      
                    <button type="submit" class="btn btn-primary waves-light" style="border-radius: 10px;" [disabled]="submit == false"
                    (click)="editContacts(
                        info.id,
                        info.whatsappUrl,
                        info.facebookUrl,
                        info.youtubeUrl,
                        info.snapChatUrl,
                        info.twitterUrl,
                        info.phoneNumber,
                        info.email,
                        info.location
                    )">
                        أدخال
                    </button>
                </div>
          </ng-template>
          </ngb-tab>
  
          <ngb-tab title="رسالتنا">
            <ng-template ngbTabContent>
                <div *ngFor="let info of message;">
          
                    <div class="md-form">
                  
                      <textarea type="text" id="message" class="md-textarea form-control cc2" (focus)="onFocus()" [(ngModel)]="info.message" name='message' mdbInput required></textarea>
                  
                      <label for="message" class="label">عنا </label>
                  
                    </div>

                    <img src="assets/imgs/loading.gif" width="165" height="120" style="margin: 0px 35.1%;" *ngIf="loading == true">
                  
                    <div class="alert alert-success" role="alert" *ngIf="doneAlert == true">تم التحميل بنجاح</div>              
                    
                    <button type="submit" class="btn btn-primary waves-light" style="border-radius: 10px;" [disabled]="submit == false"
                     (click)="editMessage(info.id,info.message)">
                        أدخال
                    </button>
              </div>
          </ng-template>
          </ngb-tab>
  
          <ngb-tab title="هدفنا">
              <ng-template ngbTabContent>
                <div *ngFor="let info of goal;">
          
                    <div class="md-form">
                  
                      <textarea type="text" id="message" class="md-textarea form-control cc2" (focus)="onFocus()" [(ngModel)]="info.message" name='message' mdbInput required></textarea>
                  
                      <label for="message" class="label">عنا </label>
                  
                    </div>

                    <img src="assets/imgs/loading.gif" width="165" height="120" style="margin: 0px 35.1%;" *ngIf="loading == true">
                  
                    <div class="alert alert-success" role="alert" *ngIf="doneAlert == true">تم التحميل بنجاح</div>              
                    
                    <button type="submit" class="btn btn-primary waves-light" style="border-radius: 10px;" [disabled]="submit == false"
                     (click)="editGoal(info.id,info.message)">
                        أدخال
                    </button>
              </div>
            </ng-template>
          </ngb-tab>
  
          <ngb-tab title="عنا">
              <ng-template ngbTabContent>
                <div *ngFor="let info of presentation;">
          
                    <div class="md-form">
                  
                      <textarea type="text" id="message" class="md-textarea form-control cc2" (focus)="onFocus()" [(ngModel)]="info.message" name='message' mdbInput required></textarea>
                  
                      <label for="whatsappUrl" class="label">عنا </label>
                  
                    </div>

                    <img src="assets/imgs/loading.gif" width="165" height="120" style="margin: 0px 35.1%;" *ngIf="loading == true">
                  
                    <div class="alert alert-success" role="alert" *ngIf="doneAlert == true">تم التحميل بنجاح</div>              
                    
                    <button type="submit" class="btn btn-primary waves-light" style="border-radius: 10px;" [disabled]="submit == false"
                     (click)="editAbout(info.id,info.message)">
                        أدخال
                    </button>
              </div>
            </ng-template>
          </ngb-tab>
  
  
        </ngb-tabset>
  
  
      </div>
      <div class="ct2"></div>
  </div>