import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Views
import { HomeComponent } from '../views/home/home.component';
import { NewsComponent } from '../views/news/news.component';
import { SingleNewsComponent } from '../views/single-news/single-news.component';
import { CshipsComponent } from '../views/cships/cships.component';
import { SingleCshipComponent } from '../views/single-cship/single-cship.component';
import { TrainingsComponent } from '../views/trainings/trainings.component';
import { OldTrainComponent } from '../views/old-train/old-train.component';
import { ProTrainComponent } from '../views/pro-train/pro-train.component';
import { YoungTrainComponent } from '../views/young-train/young-train.component';
import { BeginnersTrainComponent } from '../views/beginners-train/beginners-train.component';
import { SingleTrainingComponent } from '../views/single-training/single-training.component';
import { AboutComponent } from '../views/about/about.component';
import { LoginComponent } from '../views/login/login.component';
import { VideosComponent } from '../views/videos/videos.component';
import { GalleryComponent } from '../views/gallery/gallery.component';

//Dashboard
import { AddCarouselComponent } from '../dashboard/add-carousel/add-carousel.component';
import { AddNewsComponent } from '../dashboard/add-news/add-news.component';
import { AddVideosComponent } from '../dashboard/add-videos/add-videos.component';
import { AddTrainComponent } from '../dashboard/add-train/add-train.component';
import { AddCshipComponent } from '../dashboard/add-cship/add-cship.component';
import { AddGalleryComponent } from '../dashboard/add-gallery/add-gallery.component';
import { JoinUsComponent } from '../dashboard/join-us/join-us.component';
import { TrainingsRecevComponent } from '../dashboard/trainings-recev/trainings-recev.component';
import { AddSectionsComponent } from '../dashboard/add-sections/add-sections.component';
import { LoginRouterGuardGuard } from '../services/login-router-guard.guard';
import { DisableLoginGuard } from '../services/disable-login.guard';
import { PublicInfoComponent } from '../dashboard/public-info/public-info.component';


const routes: Routes = [
  //views
  {path:'', component:HomeComponent},
  {path:'news', component:NewsComponent,data: {state:  'news'}},
  {path:'single-news/:id', component:SingleNewsComponent,data: {state:  'single-news'}},
  {path:'championships', component:CshipsComponent,data: {state:  'championships'}},
  {path:'single-championship/:id', component:SingleCshipComponent,data: {state:  'single-championship'}},
  {path:'trainings', component:TrainingsComponent,data: {state:  'trainings'}},
  {path:'trainings-older', component:OldTrainComponent,data: {state:  'trainings-older'}},
  {path:'trainings-pro', component:ProTrainComponent,data: {state:  'trainings-pro'}},
  {path:'trainings-young', component:YoungTrainComponent,data: {state:  'trainings-young'}},
  {path:'trainings-beginner', component:BeginnersTrainComponent,data: {state:  'trainings-beginner'}},
  {path:'single-training/:id', component:SingleTrainingComponent,data: {state:  'single-training'}},
  {path:'about', component:AboutComponent,data: {state:  'about'}},
  {path:'videos', component:VideosComponent,data: {state:  'videos'}},
  {path:'gallery', component:GalleryComponent,data: {state:  'gallery'}},
  {path:'login', component:LoginComponent,data: {state:  'login'},canActivate:[DisableLoginGuard]},
  //dashboard
  {path:'add-carousel', component:AddCarouselComponent,data: {state:  'add-carousel'},canActivate:[LoginRouterGuardGuard]},
  {path:'add-news', component:AddNewsComponent,data: {state:  'add-news'},canActivate:[LoginRouterGuardGuard]},
  {path:'add-videos', component:AddVideosComponent,data: {state:  'add-videos'},canActivate:[LoginRouterGuardGuard]},
  {path:'add-trainings', component:AddTrainComponent,data: {state:  'add-trainings'},canActivate:[LoginRouterGuardGuard]},
  {path:'add-cships', component:AddCshipComponent,data: {state:  'add-cships'},canActivate:[LoginRouterGuardGuard]},
  {path:'add-gallery', component:AddGalleryComponent,data: {state:  'add-gallery'},canActivate:[LoginRouterGuardGuard]},
  {path:'add-sections', component:AddSectionsComponent,data: {state:  'add-sections'},canActivate:[LoginRouterGuardGuard]},
  {path:'view-joinus', component:JoinUsComponent,data: {state:  'view-joinus'},canActivate:[LoginRouterGuardGuard]},
  {path:'view-receivervations', component:TrainingsRecevComponent,data: {state:  'view-receivervations'},canActivate:[LoginRouterGuardGuard]},
  {path:'edit-public-info', component:PublicInfoComponent,data: {state:  'edit-public-info'},canActivate:[LoginRouterGuardGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
