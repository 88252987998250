<!-- Start Blog Section -->
<section class="blog-section ptb-100">
  <div class="container">
    <div class="blog-title">
      <h2>أحدث الفيدوهات</h2>
    </div>
    <!-- <div > -->
    <div class="row">

      <div class="col-lg-4 col-md-4 col-sm-12 col-12" *ngFor="let details of videos; index as i">
        <div class="single-blog-post">
          <figure>
            <button class="play" (click)="basicModal.show()" mdbWavesEffect>
              <mdb-icon fas icon="chevron-circle-right"></mdb-icon>
            </button>
            <img [src]="details.videoImg" alt="blog-img" width="335" height="270">
          </figure>
          <div class="blog-post-content">
            <h3><a (click)="basicModal.show()">{{details.title}}</a></h3>
          </div>
        </div>
      <div mdbModal #basicModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog"
        aria-labelledby="myBasicModalLabel" aria-hidden="true" style="position: fixed;">
        <div class="modal-dialog modal-side modal-bottom-right" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4 class="modal-title" id="myModalLabel">مشاهدة الفيديو</h4>
              <!-- <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" [src]="details.videoUrl" allowfullscreen></iframe>
                </div> -->
              <!-- <video id="video1" width="420" autoplay>
                  <source [src]="details.videoUrl">
                </video> -->
              <div class="video">
                <video controls (click)="toggleVideo()" #videoPlayer width="370">
                  <source [src]="details.videoUrl" type="video/mp4" />
                  <!-- Browser not supported -->
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
      </div>



  </div>
  <!-- </div> -->
</section>