import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private fs: AngularFirestore, private storage: AngularFireStorage) { }

  getNews(){ return this.fs.collection('news').snapshotChanges() }

  addNews(title:string,
          date:string,
          writerName:string,
          description:string,
          writerArticale:string,
          mainPhoto:File,
          writerPhoto:File)
    {
    return new Promise((resolve, reject)=> {
      
    let ref1 = this.storage.ref('news/' + mainPhoto.name)
    let ref2 = this.storage.ref('writers/' + writerPhoto.name)

    ref2.put(writerPhoto),
      ref1.put(mainPhoto).then( ()=> {
      ref1.getDownloadURL().subscribe(photoUrl => {
        ref2.getDownloadURL().subscribe(avatarUrl=> {
        this.fs.collection('news').add({
          title,  
          date,
          writerName,
          description,
          writerArticale,
          photoUrl,
          avatarUrl
        })
      })
    })
  }).then(()=>resolve())
})
}

addSponsers(
  id: string,
  photoUrl: File,
) {
  return new Promise((resolve, reject) => {
    let ref = this.storage.ref('sponsers/' + photoUrl.name)
    ref.put(photoUrl).then(() => {
      ref.getDownloadURL().subscribe(photoUrl => {
        this.fs.collection(`news/${id}/sponsers`).add({
          photoUrl,
        }).then(() => resolve())
      })
    })
  })
}

delete(id){ return this.fs.doc(`news/${id}`).delete() }

}
