import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NewsService } from 'src/app/services/news.service';
import { NgForm } from '@angular/forms';
import { News } from 'src/app/interfaces/news.interface';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {
  
  @ViewChild('newsImage') mainPhoto:ElementRef

  @ViewChild('writerImage') writerPhoto:ElementRef

   @ViewChild('photoUrl') photoUrl:ElementRef

  // @ViewChild('photoUrl') photoUrl:ElementRef


  new: News[] = []

  model: NgbDateStruct;
  
  date: {year: number, month: number};

  stringDate: string = ""

  calnderUI: boolean = false
  
  errorMessage:string = ''

  headElements = ['العنوان', 'الكاتب ', 'الخبر', 'الصورة ','الرعاة',''];

  constructor(private addNewss: NewsService,
    private calendar: NgbCalendar,
    private parserFormatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    this.addNewss.getNews().subscribe(data => {
      this.new = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }

  on:boolean = true

  off:boolean = false

  today() {
    this.model = this.calendar.getToday()
    this.stringDate = this.parserFormatter.format(this.model);
  }

  showCalanderUI() {
    this.calnderUI = true
    this.on = false
    this.off = true
  }
  hideCalnderUI() {
    this.stringDate = this.parserFormatter.format(this.model);
    this.calnderUI = false 
    this.off = false
    this.on = true
  }

  loading:boolean = false
  done:boolean = false
  submit:boolean = true

  addNew(f:NgForm) {

    this.submit = false
    
    this.loading = true
     
     let title = (<News>f.value).title,
     
        description = (<News>f.value).description,

        date = (<News>f.value).date,
        
        writerName= (<News>f.value).writerName,

        writerArticale = (<News>f.value).writerArticale,

        mainPhoto = (<HTMLInputElement>this.mainPhoto.nativeElement).files[0],

        writerPhoto = (<HTMLInputElement>this.writerPhoto.nativeElement).files[0];

     this.addNewss.addNews(title,date,writerName,description,writerArticale,mainPhoto,writerPhoto).then(()=>{
        this.loading = false
        this.done = true
        setTimeout(() => {
          this.done = false
          this.submit = true
        }, 4000);

      }).catch(error =>{
        this.errorMessage = error.message
      })
      }

      addS(id:string) {
        
        this.submit = false
    
         this.loading = true

        let gPhoto =   (<HTMLInputElement>this.photoUrl.nativeElement).files;

        console.log(gPhoto)

         for (let i = 0; i < gPhoto.length; i++) {
               this.addNewss.addSponsers(id,gPhoto[i]).then(()=>{
                 this.loading = false
                 this.done = true
                 setTimeout(() => {
                   this.done = false
                   this.submit = true
                 }, 4000);
               })
               .catch(error => console.log(error))
               if(gPhoto.length > 10000000){
                 alert("Each File should be less than 10 MB of size.");
             return;
           }
         }
     }

    delete(index){ this.addNewss.delete(this.new[index].id) }

}
