import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';


@Injectable({
  providedIn: 'root'
})
export class TrainingsService {

  constructor(private fs: AngularFirestore, private storage: AngularFireStorage) { }

  getProTrain(){ return this.fs.collection('proTrainings').snapshotChanges() }

  getYoungTrain(){ return this.fs.collection('youngTrainings').snapshotChanges() }

  getOldTrain(){ return this.fs.collection('oldTrainings').snapshotChanges() }

  getBeginnersTrain(){ return this.fs.collection('amateurTrainings').snapshotChanges() }

  getRecervations(){ return this.fs.collection('reservations').snapshotChanges() }

  deleteRecervation(id){return this.fs.doc(`reservations/${id}`).delete()}

  addProTrain(
    title:string,
    period:string,
    price:number,
    coachName:string,
    coachDetails:string,
    date:string,
    age:number,
    location:string,
    otherDetails:string,
    photoUrl:File,
    // orgName1:string,
    // orgAvatar1:File,
    // orgJob1:string,
    // orgName2:string,
    // orgAvatar2:File,
    // orgJob2:string,
    // orgName3:string,
    // orgAvatar3:File,
    // orgJob3:string,
){
  return new Promise((resolve, reject)=> {
    
  let ref1 = this.storage.ref('proTrainings/' + photoUrl.name)
  // let ref2 = this.storage.ref('proTrainings/organizers/' + orgAvatar1.name)
  // let ref3 = this.storage.ref('proTrainings/organizers/' + orgAvatar2.name)
  // let ref4 = this.storage.ref('proTrainings/organizers/' + orgAvatar3.name)      
    // ref1.put(photoUrl),
    // ref2.put(orgAvatar1),
    // ref3.put(orgAvatar2),
    ref1.put(photoUrl).then( ()=> {
    ref1.getDownloadURL().subscribe(photoUrl=> {
    // ref2.getDownloadURL().subscribe(orgAvatar1=> {
    // ref3.getDownloadURL().subscribe(orgAvatar2=> {
    // ref4.getDownloadURL().subscribe(orgAvatar3=> {
      this.fs.collection('proTrainings').add({
                  title,
                  period,
                  date,
                  price,
                  coachName,
                  coachDetails,
                  age,
                  location,
                  otherDetails,
                  photoUrl,
                  // orgName1,
                  // orgAvatar1,
                  // orgJob1,
                  // orgName2,
                  // orgAvatar2,
                  // orgJob2,
                  // orgName3,
                  // orgAvatar3,
                  // orgJob3
                })
              })
                    //   })
                    // })
                  // })
    }).then(()=>resolve())
  })
}
deleteProTrain(id){return this.fs.doc(`proTrainings/${id}`).delete()}

addBeginnersTrain(
  title:string,
  period:string,
  price:number,
  coachName:string,
  coachDetails:string,
  date:string,
  age:number,
  location:string,
  otherDetails:string,
  photoUrl:File,
){
return new Promise((resolve, reject)=> {
  
let ref1 = this.storage.ref('amateurTrainings/' + photoUrl.name)

  ref1.put(photoUrl).then( ()=> {
  ref1.getDownloadURL().subscribe(photoUrl=> {
    this.fs.collection('amateurTrainings').add({
                title,
                period,
                date,
                price,
                coachName,
                coachDetails,
                age,
                location,
                otherDetails,
                photoUrl
              })
            })
  }).then(()=>resolve())
})
}
deleteBeginnersTrain(id){return this.fs.doc(`amateurTrainings/${id}`).delete()}

addYoungTrain(
  title:string,
  period:string,
  price:number,
  coachName:string,
  coachDetails:string,
  date:string,
  age:number,
  location:string,
  otherDetails:string,
  photoUrl:File,
){
return new Promise((resolve, reject)=> {
  
let ref1 = this.storage.ref('youngTrainings/' + photoUrl.name)

  ref1.put(photoUrl).then( ()=> {
  ref1.getDownloadURL().subscribe(photoUrl=> {
    this.fs.collection('youngTrainings').add({
                title,
                period,
                date,
                price,
                coachName,
                coachDetails,
                age,
                location,
                otherDetails,
                photoUrl
              })
            })
  }).then(()=>resolve())
})
}
deleteYoungTrain(id){return this.fs.doc(`youngTrainings/${id}`).delete()}

addOldTrain(
  title:string,
  period:string,
  price:number,
  coachName:string,
  coachDetails:string,
  date:string,
  age:number,
  location:string,
  otherDetails:string,
  photoUrl:File,
){
return new Promise((resolve, reject)=> {
  
let ref1 = this.storage.ref('oldTrainings/' + photoUrl.name)

  ref1.put(photoUrl).then( ()=> {
  ref1.getDownloadURL().subscribe(photoUrl=> {
    this.fs.collection('oldTrainings').add({
                title,
                period,
                date,
                price,
                coachName,
                coachDetails,
                age,
                location,
                otherDetails,
                photoUrl
              })
            })
  }).then(()=>resolve())
})
}
deleteOldTrain(id){return this.fs.doc(`oldTrainings/${id}`).delete()}

addReservation(
  name:string,
  email:string,
  trainingTitle:string,
  phone:string,
  otherDetails:string
){
return new Promise((resolve, reject)=> {
  
    this.fs.collection('reservations').add({
      name,
      email,
      trainingTitle,
      phone,
      otherDetails
    }).then(()=>resolve())
  })
}

}
