import { AppRoutingModule } from './app-routing/app-routing.module';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from'@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { OwlModule } from 'ngx-owl-carousel';
import { AngularFireStorageModule } from '@angular/fire/storage'
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';

//Views
import { HomeComponent } from './views/home/home.component';
import { NewsComponent } from './views/news/news.component';
import { SingleNewsComponent } from './views/single-news/single-news.component';
import { CshipsComponent } from './views/cships/cships.component';
import { SingleCshipComponent } from './views/single-cship/single-cship.component';
import { TrainingsComponent } from './views/trainings/trainings.component';
import { ProTrainComponent } from './views/pro-train/pro-train.component';
import { OldTrainComponent } from './views/old-train/old-train.component';
import { YoungTrainComponent } from './views/young-train/young-train.component';
import { BeginnersTrainComponent } from './views/beginners-train/beginners-train.component';
import { SingleTrainingComponent } from './views/single-training/single-training.component';
import { AboutComponent } from './views/about/about.component';

//Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CardsCarouselComponent } from './components/cards-carousel/cards-carousel.component';
import { MessageComponent } from './components/message/message.component';
import { ParallaxSectionComponent } from './components/parallax-section/parallax-section.component';
import { VideosSectionComponent } from './components/videos-section/videos-section.component';
import { ExploreComponent } from './components/explore/explore.component';
import { LightboxGalleryComponent } from './components/lightbox-gallery/lightbox-gallery.component';
import { CounterComponent } from './components/counter/counter.component';
import { AnimatedDigitComponent } from './components/animated-digit/animated-digit.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { FooterComponent } from './components/footer/footer.component';
import { NewsCardsComponent } from './components/news-cards/news-cards.component';
import { CardsCarouselLlComponent } from './components/cards-carousel-ll/cards-carousel-ll.component';
import { CshipsCardsComponent } from './components/cships-cards/cships-cards.component';
import { TrainCardsComponent } from './components/train-cards/train-cards.component';
import { SafePipe } from './pipes/safe.pipe';
import { YcardsComponent } from './components/ycards/ycards.component';
import { OcardsComponent } from './components/ocards/ocards.component';
import { PcardsComponent } from './components/pcards/pcards.component';
import { BcardsComponent } from './components/bcards/bcards.component';
import { LoginComponent } from './views/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

//Dashboard
import { AddCarouselComponent } from './dashboard/add-carousel/add-carousel.component';
import { AddNewsComponent } from './dashboard/add-news/add-news.component';
import { AddVideosComponent } from './dashboard/add-videos/add-videos.component';
import { AddGalleryComponent } from './dashboard/add-gallery/add-gallery.component';
import { AddCshipComponent } from './dashboard/add-cship/add-cship.component';
import { AddTrainComponent } from './dashboard/add-train/add-train.component';
import { JoinUsComponent } from './dashboard/join-us/join-us.component';
import { TrainingsRecevComponent } from './dashboard/trainings-recev/trainings-recev.component';
import { format } from 'path';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddSectionsComponent } from './dashboard/add-sections/add-sections.component';
import { CarouselDirective } from './carousel.directive';
import { GalleryComponent } from './views/gallery/gallery.component';
import { VideosComponent } from './views/videos/videos.component';
import { ProviderComponent } from './components/provider/provider.component';
import { GetAccessComponent } from './components/get-access/get-access.component';
import { PublicInfoComponent } from './dashboard/public-info/public-info.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    CarouselComponent,
    CardsCarouselComponent,
    MessageComponent,
    ParallaxSectionComponent,
    VideosSectionComponent,
    ExploreComponent,
    LightboxGalleryComponent,
    CounterComponent,
    AnimatedDigitComponent,
    ContactsComponent,
    FooterComponent,
    NewsComponent,
    NewsCardsComponent,
    SingleNewsComponent,
    CshipsComponent,
    CardsCarouselLlComponent,
    CshipsCardsComponent,
    SingleCshipComponent,
    TrainingsComponent,
    ProTrainComponent,
    OldTrainComponent,
    YoungTrainComponent,
    BeginnersTrainComponent,
    SingleTrainingComponent,
    TrainCardsComponent,
    AboutComponent,
    SafePipe,
    YcardsComponent,
    OcardsComponent,
    PcardsComponent,
    BcardsComponent,
    LoginComponent,
    SidebarComponent,
    AddCarouselComponent,
    AddNewsComponent,
    AddVideosComponent,
    AddGalleryComponent,
    AddCshipComponent,
    AddTrainComponent,
    JoinUsComponent,
    TrainingsRecevComponent,
    AddSectionsComponent,
    CarouselDirective,
    GalleryComponent,
    VideosComponent,
    ProviderComponent,
    GetAccessComponent,
    PublicInfoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    OwlModule,
    AngularFireModule.initializeApp({
        apiKey: "AIzaSyA-c2wYoolHdrc7mh_vSRJAWj0ZbX0JDTQ",
        authDomain: "ksa-judo-demo.firebaseapp.com",
        databaseURL: "https://ksa-judo-demo.firebaseio.com",
        projectId: "ksa-judo-demo",
        storageBucket: "ksa-judo-demo.appspot.com",
        messagingSenderId: "60293641576",
        appId: "1:60293641576:web:38049b57e8b85586b112dd"
      }),
      AngularFirestoreModule,
      AngularFireStorageModule,
      AngularFireAuthModule,
      NgbModule,
      NgbTabsetModule,
  ],
  providers: [
     {provide:SETTINGS, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
