<!-- Start Blog Section -->
<section class="blog-section ptb-100" style="margin-top: 50px;">
<div class="container">
  <div class="blog-title">
      <h2>أخر الأخبار</h2>
      <p>كل ما هو جديد عن الأتحاد</p>
  </div>
 </div>
</section>
<div class="container">
    <div id="dataInfo">
        <div id="myCarousel" class="carousel" data-ride="carousel" test>
            <div class="carousel-inner row w-100 mx-auto" #cara="Carousel" [animationDuration]="300">

                <div class="carousel-itemm col-md-4 active" *ngFor="let head of new">
                <div class="card">
                    <figure>
                        <a [routerLink]="['/single-news', head.id]" >
                            <img [src]="head.photoUrl" alt="blog-img" height="245" width="338" style="border-radius: 19px 19px 0px 0px;">
                        </a>
                    </figure>
                    <div class="card-body">
                    <h4 class="card-title">{{head.title}}</h4>
                    <p class="card-text">{{head.date}}</p>
                    <div class="blog-btn">
                        <a href="#" class="default-btn-one" [routerLink]="['/single-news', head.id]">التفاصيل</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <a class="carousel-control-prev" role="button" (click)="cara.prev(3)">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" role="button" (click)="cara.next(3)">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
  
    </div>
  
  
  </div>
  