<app-get-access></app-get-access>
<app-sidebar></app-sidebar>
<div class="ct">
    <div class="custom"></div>
  <div class="container">
      <table mdbTable>
          <thead class="custom white-text">
            <tr>
              <th *ngFor="let head of headElements" scope="col">{{head}} </th>
            </tr>
          </thead>
          <tbody>
            <tr mdbTableCol  *ngFor="let details of data; index as i">
              <th scope="row">{{details.name}}</th>
              <td>{{details.email}}</td>
              <td>{{details.message}}</td>
              <td>{{details.phone}}</td>
              <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
              <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-body" style="text-align: center;">
                      <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                      
                      <p class="pp"> هل تريد تأكيد الحزف</p>
              
                        <div class="modal-footer">
                          
                          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                          
                          <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="delete(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>  
      </div>
      <div class="ct2"></div>
  </div>