import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GalleryCships } from 'src/app/interfaces/cshipsg.interface';
import { GalleryTeam } from 'src/app/interfaces/nteam.interface';
import { GalleryPlayers } from 'src/app/interfaces/players.interface';
import { GalleryTranings } from 'src/app/interfaces/trainingsg.interface';
import { GalleryService } from 'src/app/services/gallery.service';
import { NgForm } from '@angular/forms';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-gallery',
  templateUrl: './add-gallery.component.html',
  styleUrls: ['./add-gallery.component.scss'],
  providers: [NgbTabsetConfig] // add NgbTabsetConfig to the component providers

})
export class AddGalleryComponent implements OnInit {

   cships: GalleryCships[] = []
  
   team:GalleryTeam[] = []
  
   players:GalleryPlayers[] = []
  
   trainings:GalleryTranings[] =[]

  headElements = ['ID', 'الاسم', 'الصورة', ''];
  
  @ViewChild('image1') teamImage:ElementRef

  @ViewChild('image2') trainImage:ElementRef

  @ViewChild('image3') cshipImage:ElementRef

  @ViewChild('image4') playerImage:ElementRef

  errorMessage: string = ""

  data: GalleryCships[] = []

  submit:boolean = true

  constructor(private addGallery: GalleryService,config: NgbTabsetConfig) {
    config.justify = 'center';
    config.type = 'pills';
   }

  ngOnInit(): void {
    
    this.addGallery.getCshipsGId().subscribe(data => {
      this.cships = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.addGallery.getTraningsGId().subscribe(data => {
      this.trainings = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.addGallery.getPlayersGId().subscribe(data => {
      this.players = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.addGallery.getTeamGId().subscribe(data => {
      this.team = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }

  addNewTeam(f: NgForm) {

    this.submit = false

    document.getElementById('loadingAlert1').style.display="block";

    let title = (<GalleryCships>f.value).title,

        image = (<HTMLInputElement>this.teamImage.nativeElement).files[0];

       this.addGallery.addTeamG(title,image).then(()=>{
        document.getElementById('loadingAlert1').style.display="none";
        document.getElementById('alert1').style.display="block";
        this.submit = true
       }).catch(error=>{
         this.errorMessage = error.message
       })
  
    }
    deleteTeam(index){ this.addGallery.deleteTeamG(this.team[index].id) }

     addNewTraining(f2: NgForm) {

      this.submit = false

       document.getElementById('loadingAlert2').style.display="block";
  
       let title = (<GalleryCships>f2.value).title,
  
           image = (<HTMLInputElement>this.trainImage.nativeElement).files[0];
  
          this.addGallery.addTraningsG(title,image).then(()=>{
            document.getElementById('loadingAlert2').style.display="none";
            document.getElementById('alert2').style.display="block";
            this.submit = true
          }).catch(error=>{
            this.errorMessage = error.message
          })
    
       }
       deletetraining(index){ this.addGallery.deleteTraningsG(this.trainings[index].id) }

       addNewCship(f3: NgForm) {
        
        this.submit = false

         document.getElementById('loadingAlert3').style.display="block";
    
        let title = (<GalleryCships>f3.value).title,
    
             image = (<HTMLInputElement>this.cshipImage.nativeElement).files[0];
    
            this.addGallery.addCshipsG(title,image).then(()=>{
              document.getElementById('loadingAlert3').style.display="none";
              document.getElementById('alert3').style.display="block";
              this.submit = true
            }).catch(error=>{
              this.errorMessage = error.message
            })
      
         }      
         deletecship(index){ this.addGallery.deleteCshipsG(this.cships[index].id) }

         addNewPlayer(f4: NgForm) {
          
          this.submit = false

           document.getElementById('loadingAlert4').style.display="block";
      
           let title = (<GalleryCships>f4.value).title,
      
               image = (<HTMLInputElement>this.playerImage.nativeElement).files[0];
      
              this.addGallery.addPlayersG(title,image).then(()=>{
                document.getElementById('loadingAlert4').style.display="none";
                document.getElementById('alert4').style.display="block";
                this.submit = true
              }).catch(error=>{
                this.errorMessage = error.message
              })
        
           }  

         deleteplayer(index){ this.addGallery.deletePlayersG(this.players[index].id) }

}
