<div class="container" style="margin-top:70px;">
    <div class="blog-title">
      <h2>كل البطولات</h2>
    </div>
    <div class="row">
      
      <div class="col-md-4" *ngFor="let details of cship;">
        <div class="single-blog-post">
          <figure>
              <a [routerLink]="['/single-championship', details.id]">
                  <img [src]="details.mainPhoto" alt="blog-img" height="240">
              </a>
          </figure>
  
          <div class="blog-post-content">
              <span>{{details.date}}</span>
              <h3><a [routerLink]="['/single-championship', details.id]">{{details.title}}</a></h3>
              <div class="blog-btn-cshpis">
                 <img src="assets/imgs/troffi.png" height="30">
                    <p class="csp">{{details.status}}</p>
              </div>
          </div>
      </div>
      </div>

    </div>
  </div>
  