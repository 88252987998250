import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DbAuthService } from './db-auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginRouterGuardGuard implements CanActivate {

  constructor(private access: DbAuthService, private router: Router){}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise(resolve => {
        this.access.user.subscribe(user => {
          if (!user){
              this.router.navigate(['/login'])
              resolve(false)
          }
          else resolve(true)
        })
      })  
    }
  
}
