<div class="container">
    <div class="blog-title">
      <!-- <h2>كل الأخبار</h2> -->
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="single-blog-post">
          <figure>
              <a href="#">
                  <img src="assets/imgs/news-1.png" alt="blog-img" height="240">
              </a>
          </figure>
  
          <div class="blog-post-content">
              <span>22/2/2019</span>
              <h3><a href="#">عنوان الدورة</a></h3>
              <div class="blog-btn">
                  <a href="#" class="default-btn-one" routerLink="/single-training">التفاصيل</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-4">
        <div class="single-blog-post">
          <figure>
              <a href="#">
                  <img src="assets/imgs/news-1.png" alt="blog-img" height="240">
              </a>
          </figure>
  
          <div class="blog-post-content">
              <span>22/2/2019</span>
              <h3><a href="#">عنوان الدورة</a></h3>
              <div class="blog-btn">
                  <a href="#" class="default-btn-one" routerLink="/single-training">التفاصيل</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-4">
        <div class="single-blog-post">
          <figure>
              <a href="#">
                  <img src="assets/imgs/news-1.png" alt="blog-img" height="240">
              </a>
          </figure>
  
          <div class="blog-post-content">
              <span>22/2/2019</span>
              <h3><a href="#">عنوان الدورة</a></h3>
              <div class="blog-btn">
                  <a href="#" class="default-btn-one" routerLink="/single-training">التفاصيل</a>
              </div>
          </div>
      </div>
      </div>
      <div class="col-md-3"></div>
      <div class="col-md-3"></div>
      <div class="col-md-3"></div>
    </div>
  </div>
  