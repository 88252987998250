<div class="parallax">
  <div class="overlay op7"></div>


  <div class="container">
        <div class="parallax-header">
        <h2>تعرف علي المؤسسين</h2>
        <p>كل الشكر و التقدير لكل من ساعم في بناء و تطوير الأتحاد</p>
      </div>
    <div class="row" style="justify-content: center;">

      <div class="col-lg-3 col-md-3 col-sm-12 col-12" *ngFor="let info of establisher">
        <img [src]="info.avatarUrl" alt="مؤسس" class="estab-avatar">
        <h4> {{info.title}}  /  {{info.name}} </h4>
        <p>{{info.jop}}</p>
      </div>

    </div>
  </div>
</div>
