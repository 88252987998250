import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import { GalleryService } from 'src/app/services/gallery.service';
import { GalleryPlayers } from 'src/app/interfaces/players.interface';
import { GalleryTeam } from 'src/app/interfaces/nteam.interface';
import { GalleryCships } from 'src/app/interfaces/cshipsg.interface';
import { GalleryTranings } from 'src/app/interfaces/trainingsg.interface';



@Component({
  selector: 'app-lightbox-gallery',
  templateUrl: './lightbox-gallery.component.html',
  styleUrls: ['./lightbox-gallery.component.scss']
})
export class LightboxGalleryComponent implements OnInit {

  player: GalleryPlayers[] = []
  
  team: GalleryTeam[] = []

  cship:GalleryCships[] = []

  training:GalleryTranings[] = []

  constructor(private gallery: GalleryService) { }

  ngOnInit(): void {
    $(document).ready(function(){

      $(".filter-button").click(function(){
          var value = $(this).attr('data-filter');

          if (value == "all")
          {
              $('.filter').removeClass('hidden');
              $('.filter').show(500);
          }
          else
          {
  //            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
  //            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
              $(".filter").not('.'+value).hide(400);
              $('.filter').filter('.'+value).show(300);

          }
      });
      $('.all').click(function() {
        $('.nteam-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.players-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.trainings-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.cships-btn').css({"background-color": "#e3e3e3", "color": "#707070"})

        $(this).css({"background-color": "#37b349", "color": "white"})
      })
      $('.nteam-btn').click(function() {
        $('.all').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.players-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.trainings-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.cships-btn').css({"background-color": "#e3e3e3", "color": "#707070"})

        $(this).css({"background-color": "#37b349", "color": "white"})
      })
      $('.players-btn').click(function() {
        $('.nteam-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.all').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.trainings-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.cships-btn').css({"background-color": "#e3e3e3", "color": "#707070"})

        $(this).css({"background-color": "#37b349", "color": "white"})
      })
      $('.trainings-btn').click(function() {
        $('.nteam-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.players-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.all').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.cships-btn').css({"background-color": "#e3e3e3", "color": "#707070"})

        $(this).css({"background-color": "#37b349", "color": "white"})
      })
      $('.cships-btn').click(function() {
        $('.nteam-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.players-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.trainings-btn').css({"background-color": "#e3e3e3", "color": "#707070"})
        $('.all').css({"background-color": "#e3e3e3", "color": "#707070"})

        $(this).css({"background-color": "#37b349", "color": "white"})
      })
  });
    this.gallery.getTeamG().subscribe(data => this.team = data)
    this.gallery.getTraningsG().subscribe(data => this.training = data)
    this.gallery.getCshipsG().subscribe(data => this.cship = data)
    this.gallery.getPlayersG().subscribe(data => this.player = data)
  }

}
