<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<!-- Page banner -->
<section class="page-banner-single" style="padding-top: 75px;">
  <div class="container">
    <div class="page-banner-content">
      <h5>الرئيسية >  الأخبار  >  الخبر </h5>
    </div>
  </div>
</section>
<!-- End Page banner -->

<!-- Start Single News Section -->
<section class="single-news-section ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="sidebar mt-0">

          <div class="widget widget-recent-entries">
            <h3 class="widget-title">
              <span>تواصل معنا</span>
            </h3>

            <ul *ngFor="let info of contacts">
              <li>
                <p class="date">الموقع</p>
                <h5>{{info.location}}</h5>
              </li>

              <li>
                <p class="date">الهاتف</p>
                <h5>{{info.phoneNumber}}</h5>
              </li>

              <li>
                <p class="date">البريد الألكتروني</p>
                <h5>{{info.email}}</h5>
              </li>
            </ul>
          </div>

          <div class="widget widget-recent-entries">
            <h3 class="widget-title">
              <span>برعاية</span>
            </h3>
            <div class="container">
              <div class="row">
                <div class="col-md-12 null" *ngIf="sponsorsnull == true">لا يوجد رعاة</div>
                <div class="col-md-6" *ngFor="let data of sponsors; index as i" style="margin-bottom: 20px;">
                    <img class="ads" [src]="data.photoUrl" width="130">
                </div>
              </div>
            </div>
          </div>

          <div class="widget widget-recent-entries">
            <h3 class="widget-title">
              <span>تابعنا عبر منصات التواص الأجتماعي</span>
            </h3>
            <div class="row platforms" *ngFor="let info of contacts">
              <div class="col-md-2 col-sm-12 col-xm-12">
                <div class="facebook">
                  <a href="{{info.facebookUrl}}">
                     <i class="fab fa-facebook"></i>
                  </a>
              </div>
              </div>
              <div class="col-md-2 col-sm-12 col-xm-12">
                <div class="whatsapp">
                  <a href="{{info.whatsappUrl}}">
                    <i class="fab fa-whatsapp"></i>
                  </a>
                </div>
              </div>
              <div class="col-md-2 col-sm-12 col-xm-12">
                <div class="twiiter">
                  <a href="{{info.twitterUrl}}">
                    <i class="fab fa-twitter"></i>
                  </a>
              </div>
              </div>
              <div class="col-md-2 col-sm-12 col-xm-12">
                <div class="snap">
                  <a href="{{info.snapChatUrl}}">
                    <i class="fab fa-snapchat-ghost"></i>    
                  </a>
                </div>
              </div>
              <div class="col-md-2 col-sm-12 col-xm-12">
                <div class="youtube">
                  <a href="{{info.youtubeUrl}}">
                    <i class="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="news-details">
          <div class="article-img">
            <img [src]="photoUrl" alt="blog-details">
          </div>

          <div class="article-content">
            <ul class="blog-list">
              <li>
                {{date}}<i class="fa fa-calendar-week"></i>
              </li>
            </ul>
            <h3>{{title}} </h3>
            <p> {{description}}</p>
          </div>
        </div>

        <div class="post-comments">
          <h3>كاتب المقال</h3>
          <div class="single-comment">
            <div class="container">
              <div class="row" style="flex-direction: row-reverse;">
                <div class="col-md-3">
                  <div class="comment-img">
                    <img [src]="avatarUrl" width= '110' style="border-radius: 400px;">
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="comment-content">
                    <h4>{{writerName}}</h4>
                    <p>{{writerArticale}}</p>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  <!-- footer-->
  <app-footer></app-footer>
  <!-- /footer-->
<!-- End Single News Section -->
