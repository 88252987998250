import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';


@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private fs: AngularFirestore, private storage: AngularFireStorage) { }

  getCshipsG(){ return this.fs.collection('cShipsGallery').valueChanges() }

  getTraningsG(){ return this.fs.collection('trainingsGallery').valueChanges() }

  getPlayersG(){ return this.fs.collection('playersGallery').valueChanges() }

  getTeamG(){ return this.fs.collection('nTeamGallery').valueChanges() }


  getCshipsGId(){ return this.fs.collection('cShipsGallery').snapshotChanges() }

  getTraningsGId(){ return this.fs.collection('trainingsGallery').snapshotChanges() }

  getPlayersGId(){ return this.fs.collection('playersGallery').snapshotChanges() }

  getTeamGId(){ return this.fs.collection('nTeamGallery').snapshotChanges() }

  addTeamG(title:string, image:File){

    return new Promise((resolve, reject)=> {
      
    let ref = this.storage.ref('nTeamGallery/' + image.name)
    
    ref.put(image).then( ()=> {
      
      ref.getDownloadURL().subscribe(photoUrl => {
      
        this.fs.collection('nTeamGallery').add({
          title,
          photoUrl
        }).then(()=>resolve())
      
      })
    })
  
  })
}
deleteTeamG(id){ return this.fs.doc(`nTeamGallery/${id}`).delete() }

 addCshipsG(title:string, image:File){

   return new Promise((resolve, reject)=> {
    
   let ref = this.storage.ref('cShipsGallery/' + image.name)
  
   ref.put(image).then( ()=> {
    
     ref.getDownloadURL().subscribe(photoUrl => {
    
       this.fs.collection('cShipsGallery').add({
         title,
         photoUrl
       }).then(()=>resolve())
    
     })
   })

 })
 }
 deleteCshipsG(id){ return this.fs.doc(`cShipsGallery/${id}`).delete() }

 addTraningsG(title:string, image:File){

   return new Promise((resolve, reject)=> {
    
   let ref = this.storage.ref('trainingsGallery/' + image.name)
  
   ref.put(image).then( ()=> {
    
     ref.getDownloadURL().subscribe(photoUrl => {
    
       this.fs.collection('trainingsGallery').add({
         title,
         photoUrl
       }).then(()=>resolve())
    
     })
   })

 })
 }
 deleteTraningsG(id){ return this.fs.doc(`trainingsGallery/${id}`).delete() }

 addPlayersG(title:string, image:File){

   return new Promise((resolve, reject)=> {
    
   let ref = this.storage.ref('playersGallery/' + image.name)
  
   ref.put(image).then( ()=> {
    
     ref.getDownloadURL().subscribe(photoUrl => {
    
       this.fs.collection('playersGallery').add({
         title,
         photoUrl
       }).then(()=>resolve())
    
     })
   })

 })
 }
 deletePlayersG(id){ return this.fs.doc(`playersGallery/${id}`).delete() }

}
