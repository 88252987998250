import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-train-cards',
  templateUrl: './train-cards.component.html',
  styleUrls: ['./train-cards.component.scss']
})
export class TrainCardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
