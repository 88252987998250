import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class SectionsService {

  constructor(private fs: AngularFirestore, private storage: AngularFireStorage) { }

  getPlayer(){ return this.fs.collection('playersSection').snapshotChanges() }

  getReferee(){ return this.fs.collection('refereesSection').snapshotChanges() }

  getAdmin(){ return this.fs.collection('adminsSection').snapshotChanges() }

  getCoach(){ return this.fs.collection('coacehsSection').snapshotChanges() }

  addPlayer(name:string,
            club:string,
            avatarUrl:File
            )
    {
    return new Promise((resolve, reject)=> {
      
    let ref = this.storage.ref('playersSection/' + avatarUrl.name)
    
    ref.put(avatarUrl).then( ()=> {
      
      ref.getDownloadURL().subscribe(avatarUrl => {
      
        this.fs.collection('playersSection').add({
          name,
          avatarUrl,
          club
        }).then(()=>resolve())
      
      })
    })
  
  })
}
deletePlayer(id){ return this.fs.doc(`playersSection/${id}`).delete() }

addReferee(name:string,
           club:string,
           avatarUrl:File)
{
return new Promise((resolve, reject)=> {

  let ref = this.storage.ref('refereesSection/' + avatarUrl.name)

  ref.put(avatarUrl).then( ()=> {

  ref.getDownloadURL().subscribe(avatarUrl => {

  this.fs.collection('refereesSection').add({
    name,
    avatarUrl,
    club
    }).then(()=>resolve())
    })
  })
 })
}

deleteReferee(id){ return this.fs.doc(`refereesSection/${id}`).delete() }

addAdmin(name:string,
        club:string,
        avatarUrl:File)
{
  return new Promise((resolve, reject)=> {

  let ref = this.storage.ref('adminsSection/' + avatarUrl.name)

  ref.put(avatarUrl).then( ()=> {

  ref.getDownloadURL().subscribe(avatarUrl => {

  this.fs.collection('adminsSection').add({
    name,
    avatarUrl,
    club
    }).then(()=>resolve())
  })
 })
})
}

deleteAdmin(id){ return this.fs.doc(`adminsSection/${id}`).delete() }

addCoach(name:string,
          club:string,
          avatarUrl:File)
{
  return new Promise((resolve, reject)=> {

  let ref = this.storage.ref('coacehsSection/' + avatarUrl.name)

  ref.put(avatarUrl).then( ()=> {

  ref.getDownloadURL().subscribe(avatarUrl => {

  this.fs.collection('coacehsSection').add({
    name,
    avatarUrl,
    club
    }).then(()=>resolve())
  })
 })
})
}

deleteCoach(id){ return this.fs.doc(`coacehsSection/${id}`).delete() }
}
