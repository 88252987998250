import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { SectionsService } from 'src/app/services/sections.service';
import { Player } from 'src/app/interfaces/playersSec.interface';
import { Referee } from 'src/app/interfaces/refereesSec.interface';
import { Admin } from 'src/app/interfaces/adminsSec.interface';
import { Coach } from 'src/app/interfaces/coachesSec.interface';
import { EstablishersService } from 'src/app/services/establishers.service';
import { Establishers } from 'src/app/interfaces/establishers';

@Component({
  selector: 'app-add-sections',
  templateUrl: './add-sections.component.html',
  styleUrls: ['./add-sections.component.scss'],
  providers: [NgbTabsetConfig] // add NgbTabsetConfig to the component providers

})
export class AddSectionsComponent implements OnInit {

  player: Player[] = []
  
  referee:Referee[] = []
 
  admin:Admin[] = []
 
  coach:Coach[] =[]

  establisher: Establishers[] = []

 headElements = ['الاسم', 'النادي', 'الصورة', ''];

 headElements2 = ['الاسم', 'الوظيفة', 'اللقب', 'الصورة', ''];

 @ViewChild('image1') playerAvatar:ElementRef

 @ViewChild('image2') refereeAvatar:ElementRef

 @ViewChild('image3') adminAvatar:ElementRef

 @ViewChild('image4') coachAvatar:ElementRef

 @ViewChild('image5') establisherAvatar:ElementRef

 errorMessage:string = ""

 submit: boolean = true

 constructor(private addSection: SectionsService, config: NgbTabsetConfig , private establishers:EstablishersService) {
  config.justify = 'center';
  config.type = 'pills';
 }

  ngOnInit(): void {

    this.addSection.getPlayer().subscribe(data => {
      this.player = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.addSection.getReferee().subscribe(data => {
      this.referee = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.addSection.getAdmin().subscribe(data => {
      this.admin = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.addSection.getCoach().subscribe(data => {
      this.coach = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.establishers.getEstablishersId().subscribe(data => {
      this.establisher = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

  }

  addNewPlayer(f:NgForm){
 
    this.submit = false

    document.getElementById('loadingAlert1').style.display="block";

    let name = (<Player>f.value).name,

        club = (<Player>f.value).club,

        avatarUrl = (<HTMLInputElement>this.playerAvatar.nativeElement).files[0];

        this.addSection.addPlayer(name,club,avatarUrl).then(()=>{
          
          document.getElementById('loadingAlert1').style.display="none";

          document.getElementById('alert1').style.display="block";
            
          this.submit = true
        }).catch(error=>{
          this.errorMessage = error.message
        })
  
      }
      
      deletePlayer(index){ this.addSection.deletePlayer(this.player[index].id) }


  addNewReferee(f2:NgForm){
    
    this.submit = false

    document.getElementById('loadingAlert2').style.display="block";

    let name = (<Referee>f2.value).name,

    club = (<Referee>f2.value).club,

    avatarUrl = (<HTMLInputElement>this.refereeAvatar.nativeElement).files[0];
  
    this.addSection.addReferee(name,club,avatarUrl).then(()=>{
          
      document.getElementById('loadingAlert2').style.display="none";

      document.getElementById('alert2').style.display="block";
    
      this.submit = true

    }).catch(error=>{
      this.errorMessage = error.message
    })

  }
  
  deleteReferee(index){ this.addSection.deleteReferee(this.referee[index].id) }


  addNewAdmin(f3:NgForm){
    
    this.submit = false
    
    document.getElementById('loadingAlert3').style.display="block";

    let name = (<Admin>f3.value).name,

    club = (<Admin>f3.value).club,

    avatarUrl = (<HTMLInputElement>this.adminAvatar.nativeElement).files[0];
  
    this.addSection.addAdmin(name,club,avatarUrl).then(()=>{
          
      document.getElementById('loadingAlert3').style.display="none";

      document.getElementById('alert3').style.display="block";   

      this.submit = true
    }).catch(error=>{
      this.errorMessage = error.message
    })

  }
  
  deleteAdmin(index){ this.addSection.deleteAdmin(this.admin[index].id) }


  addNewCoach(f4:NgForm){

    this.submit = false

    document.getElementById('loadingAlert4').style.display="block";

    let name = (<Coach>f4.value).name,

    club = (<Coach>f4.value).club,

    avatarUrl = (<HTMLInputElement>this.coachAvatar.nativeElement).files[0];

    this.addSection.addCoach(name,club,avatarUrl).then(()=>{
          
      document.getElementById('loadingAlert4').style.display="none";

      document.getElementById('alert4').style.display="block";
    
      this.submit = true
    }).catch(error=>{
      this.errorMessage = error.message
    })

  }
  
  deleteCoach(index){ this.addSection.deleteCoach(this.coach[index].id) }

  addEstablisher(f5:NgForm){

    this.submit = false

    document.getElementById('loadingAlert5').style.display="block";

    let name = (<Coach>f5.value).name,

    title = (<Establishers>f5.value).title,

    jop = (<Establishers>f5.value).jop,

    avatarUrl = (<HTMLInputElement>this.establisherAvatar.nativeElement).files[0];

    this.establishers.addEstablisher(name,jop,title,avatarUrl).then(()=>{
          
      document.getElementById('loadingAlert5').style.display="none";

      document.getElementById('alert5').style.display="block";
    
      this.submit=true
    }).catch(error=>{
      this.errorMessage = error.message
    })

  }

  deleteEstablisher(index){ this.establishers.deleteEstablisher(this.establisher[index].id) }


}
