import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class EstablishersService {

  constructor(private fs:AngularFirestore, private storage:AngularFireStorage) { }

  getEstablishers(){
    return this.fs.collection('establishers').valueChanges()
  }

  getEstablishersId(){
    return this.fs.collection('establishers').snapshotChanges()
  }

  addEstablisher(
    name:string,
    jop:string,
    title:string,
    avatarUrl:File,
  ){
    return new Promise((resolve, reject) => {
      let ref = this.storage.ref('establishers/' + avatarUrl.name)
      ref.put(avatarUrl).then(() => {
        ref.getDownloadURL().subscribe(avatarUrl => {
          this.fs.collection(`establishers`).add({
            name,
            jop,
            title,
            avatarUrl
          }).then(() => resolve())
        })
      })
    })
  }

  deleteEstablisher(id:string){
    this.fs.doc(`establishers/${id}`).delete()
  }
}
