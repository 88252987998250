<div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="explore-championships">
          <div class="overlay"></div>
            <div class="row pt-30">

              <div class="col-md-4">
                <a routerLink="/championships">
                  <mdb-icon fas icon="chevron-left"></mdb-icon>
               </a>
              </div>

              <div class="col-md-4 postion-relative">
                <p>أستكشف</p>
                <p class="bold-p"> البطولات</p>
              </div>

              <div class="col-md-4">
                <img class="cships" src="assets/imgs/cships.png" width="70">
              </div>

            </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="trainings">
          <div class="overlay"></div>
          <div class="row pt-30">

            <div class="col-md-4">
              <a routerLink="/trainings">
                <mdb-icon fas icon="chevron-left"></mdb-icon>
              </a>
            </div>

            <div class="col-md-4 postion-relative">
              <p>الدورات</p>
              <p class="bold-p"> التدربية</p>
            </div>

            <div class="col-md-4">
              <img class="medals" src="assets/imgs/medals.png" width="130">
            </div>

          </div>
        </div>
      </div>
    </div>
</div>
