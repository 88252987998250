import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';


@Injectable({
  providedIn: 'root'
})
export class PublicInfoService {

  constructor(private fs: AngularFirestore) { }

  getMessage() {
    return this.fs.collection('ourMessage').valueChanges()
  }

  getMessageId() {
    return this.fs.collection('ourMessage').snapshotChanges()
  }

  editMessage(
    id: string,
    message: string,
    ){
    return new Promise((resolve, reject) => {
    this.fs.doc(`ourMessage/${id}`).update({
      message
    }).then(()=> resolve())
  })
  }


  getGoal() {
    return this.fs.collection('ourGoal').valueChanges()
  }

  getGoalId() {
    return this.fs.collection('ourGoal').snapshotChanges()
  }

  editGoal(
    id: string,
    message: string,
    ){
    return new Promise((resolve, reject) => {
    this.fs.doc(`ourGoal/${id}`).update({
      message
    }).then(()=> resolve())
  })
  }

  
  getPresentation() {
    return this.fs.collection('presentation').valueChanges()
  }

  getPresentationId() {
    return this.fs.collection('presentation').snapshotChanges()
  }

  editPresentation(
    id: string,
    message: string,
    ){
    return new Promise((resolve, reject) => {
    this.fs.doc(`presentation/${id}`).update({
      message
    }).then(()=> resolve())
  })
  }

  
  getContacts() {
    return this.fs.collection('contacts').valueChanges()
  }

  getContactsId() {
    return this.fs.collection('contacts').snapshotChanges()
  }

  editContacts(
    id: string,
    whatsappUrl: string,
    facebookUrl: string,
    youtubeUrl: string,
    snapChatUrl: string,
    twitterUrl: string,
    phoneNumber: string,
    email: string,
    location: string
  ) {
    return new Promise((resolve, reject) => {
    this.fs.doc(`contacts/${id}`).update({
      whatsappUrl,
      facebookUrl,
      youtubeUrl,
      snapChatUrl,
      twitterUrl,
      phoneNumber,
      email,
      location
    }).then(()=> resolve())
  })
  }

}
