import { Component, OnInit } from '@angular/core';
import { CarouselService } from 'src/app/services/carousel.service';
import { Carousel } from 'src/app/interfaces/carousel.interface';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements OnInit {
  


  carousels: Carousel[] = []
  

  constructor(private car: CarouselService) { }
  
  ngOnInit() {
    this.car.getCarousel().subscribe(data => this.carousels = data)
  }

}
