<div class="contact-us">
  <div class="contact-header">
    <h3>تواصل معنا</h3>
    <p>كن علي اتصال مباشر معنا دائما</p>
  </div>
  <div class="contact-content">
    <div class="row" style="margin: 0;">
      <div class="col-md-6 col-sm-12 col-xs-12 contact-info" *ngFor="let info of contacts">

        <div class="loaction">
          <div class="col-md-2 col-md-2-map-marker">
                <i class="fas fa-map-marker-alt"></i>
          </div>
          <div class="col-md-10">
                <p class="title">الموقع</p>
                <p class="info">{{info.location}}</p>
          </div>

        </div>

        <div class="phone">
          <div class="col-md-2 col-md-2-phone">
               <i class="fas fa-phone-alt"></i>
          </div>
          <div class="col-md-10">
                <p class="title">الهاتف</p>
                <p class="info">{{info.phoneNumber}}</p>
          </div>

        </div>

        <div class="mail">
          <div class="col-md-2 col-md-2-envelope">
                <i class="fas fa-envelope"></i>
          </div>
          <div class="col-md-10" style="padding-top: 11px;">
                <p class="title">البريد الألكتروني</p>
                <p class="info">{{info.email}}</p>
          </div>
        </div>

      </div>
      <div class="col-md-6 col-sm-12 col-xs-12">
          <!--Google map-->
          <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 310px;">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14505.169023836756!2d46.6465485!3d24.6480667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4ff5c16dc1054118!2z2KfZhNin2KrYrdin2K8g2KfZhNiz2LnZiNiv2Yog2YTZhNis2YjYr9mI!5e0!3m2!1sar!2seg!4v1598456486578!5m2!1sar!2seg" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        
          </div>

          <!--Google Maps-->
      </div>
    </div>
  </div>
</div>

