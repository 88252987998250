<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<!-- Page banner -->
<section class="page-banner-single" style="padding-top: 70px;"> 
    <div class="container">
      <div class="page-banner-content">
      </div>
    </div>
  </section>
  <!-- End Page banner -->
  
  <!-- Start Single News Section -->
  <section class="single-news-section ptb-100">
    <div class="container">
      <div class="row">

        <div class="col-lg-12 col-md-12">
            <div class="news-details">
              <div class="article-img">
                 <img [src]="mainPhoto" alt="blog-details">
              </div>
            </div>
            <div class="title">
                <h3>{{title}}</h3>
                <div class="blog-btn-cshpis">
                    <img src="assets/imgs/troffi.png" height="30">
                        <p class="csp">{{status}}</p>
                  </div>
            </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="sidebar mt-0">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="widget widget-recent-entries info-basic">
                            <h3 class="widget-title">
                                <span>المعلومات الأساسية</span>
                            </h3>
                            <ul>
                                <li>
                                    <img src="assets/imgs/appointment.png" width="25">
                                    <p class="date">التاريخ</p>
                                    <h5>{{date}}</h5>
                                </li>
                        
                                <li>
                                    <img src="assets/imgs/kimono.png" width="25">
                                    <p class="date"> السن</p>
                                    <h5>{{age}}</h5>
                                </li>
                        
                                <li>
                                    <img src="assets/imgs/place.png" width="15">
                                    <p class="date"> الموقع</p>
                                    <h5>{{location}}</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="widget widget-recent-entries">
                            <h3 class="widget-title">
                                <span>المراكز</span>
                            </h3>
                            <ul>
                                <li>
                                    <img src="assets/imgs/first.png" width="30">
                                    <p class="date">المركز الأول</p>
                                    <h5>{{player1}}</h5>
                                </li>
                        
                                <li>
                                    <img src="assets/imgs/secound.png" width="30">
                                    <p class="date">المركز الثاني</p>
                                    <h5>{{player2}}</h5>
                                </li>
                        
                                <li>
                                    <img src="assets/imgs/third.png" width="30">
                                    <p class="date">المركز الثالث</p>
                                    <h5>{{player3}}</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                     <div class="col-md-6">
                        <div class="widget widget-recent-entries">
                            <h3 class="widget-title">
                                <span>المنظمون</span>
                            </h3>
                            <ul>
                              <li class="null-orgs" *ngIf="nullOrgs == true">لا يوجد منظمون</li>

                                <li *ngFor="let data of orgs; index as i">
                                    <img [src]="data.orgAvatar" width="50">
                                    <p class="date"> {{data.orgName}}</p>
                                    <h5>{{data.orgJob}} </h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="widget widget-recent-entries">
                            <h3 class="widget-title">
                                <span>تفاصيل أخري</span>
                            </h3>
                            <div class="notes"> 
                              <p>{{otherDetails}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End Single News Section -->
   <div class="container">
    <div class="row">
      <div class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h1 class="gallery-title">صور البطولة</h1>
      </div>
      <br>

      <div class="null-photos-div" *ngIf="nullPhotos == true" >
          <h5>لا يوجد صور</h5>
      </div>

      <div class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6" *ngFor="let data of photos; index as i">
        <div class="open-img" style="display: none;">
          <a (click)="basicModal.show()">
            <mdb-icon fas icon="search-plus"></mdb-icon>
          </a>
        </div>
         <img [src]="data.gPhoto" class="img-responsive" width="365" height="450">
         <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
           <div class="modal-content">
             <div class="modal-body" style="text-align:center">
               <h4 class="modal-title" id="myModalLabel">عرض الصورة</h4>
                <img [src]="data.gPhoto" class="img-responsive" width="365" height="450">
             </div>
           </div>
         </div>
       </div>
      </div>
    </div>
  </div>

    <!-- footer-->
    <app-footer></app-footer>
    <!-- /footer-->


