import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Access } from 'src/app/interfaces/access';
import { Router } from '@angular/router';
import { DbAuthService } from 'src/app/services/db-auth.service';

@Component({
  selector: 'app-get-access',
  templateUrl: './get-access.component.html',
  styleUrls: ['./get-access.component.scss']
})
export class GetAccessComponent implements OnInit {

  constructor(private access:DbAuthService) { }

  ngOnInit(): void {}

  signOut() {
    this.access.logout()
  }

}
