import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Video } from 'src/app/interfaces/videos.interface';
import { VideosService } from 'src/app/services/videos.service';

@Component({
  selector: 'app-add-videos',
  templateUrl: './add-videos.component.html',
  styleUrls: ['./add-videos.component.scss']
})
export class AddVideosComponent implements OnInit {

  @ViewChild('image') image:ElementRef

  @ViewChild('video') video:ElementRef

  data: Video[] = []

  headElements = ['العنوان', 'الصورة', 'الفيديو', ''];

  thereIsError: boolean = false

  errorMessage:string = ""

  submit: boolean = true

  loading:boolean = false

  done:boolean = false
  
  constructor(private addVideoss: VideosService) { }

  ngOnInit(): void {
    this.addVideoss.getVideosId().subscribe(data => {
      this.data = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }

  addNew(f: NgForm) {

    this.submit = false

    this.loading = true

    let title = (<Video>f.value).title,

        videoImgg= (<HTMLInputElement>this.image.nativeElement).files[0],

        videoUrll= (<HTMLInputElement>this.video.nativeElement).files[0];

        this.addVideoss.addVideos(title,videoUrll,videoImgg).then(()=>{
          this.loading = false
          this.done= true
          setTimeout(() => {
            this.done = false
            this.submit = true
          }, 4000);
        }).catch(error=>{
          this.thereIsError = true          
          this.errorMessage = error.message
        })
  
      }
      delete(index){ this.addVideoss.delete(this.data[index].id) }
    }
