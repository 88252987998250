import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute } from'@angular/router'
import { Cship } from 'src/app/interfaces/cship.interface'
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { CsPhotos } from 'src/app/interfaces/cs-photos';
import { CsOrgs } from 'src/app/interfaces/cs-orgs';

@Component({
  selector: 'app-single-cship',
  templateUrl: './single-cship.component.html',
  styleUrls: ['./single-cship.component.scss']
})
export class SingleCshipComponent implements OnInit {

  @Input() singlePage: Cship;

  id:string;

  linkRef: AngularFirestoreDocument<any>;

  linkRef2: AngularFirestoreCollection<any>;

  link: Observable<any>;
  
  link2: Observable<any>;

  photos: CsPhotos[] = []

  orgs: CsOrgs[] = []

  photosLenght: Number = null

  nullPhotos: Boolean = false

  orgsLenght: Number = null

  nullOrgs: Boolean = false

  title:string = ''

  status:string = ''

  date:string = ''

  age:number = null

  location:string = ''

  player1:string = ''

  player2:string = ''

  player3:string = ''

  mainPhoto:string = ''

  otherDetails:string = ''

  constructor(private route: ActivatedRoute,private fs: AngularFirestore) { }

  ngOnInit() {
   
    this.route.paramMap.subscribe(params => this.id = params.get('id'))
  
     
     this.linkRef = this.fs.collection('cships').doc(`${this.id}`)
     
     this.link = this.linkRef.valueChanges();
  
     this.link.subscribe(data=>{
       this.singlePage = data
       this.mainPhoto = data.mainPhoto
       this.title = data.title
       this.status = data.status
       this.date = data.date
       this.age = data.age
       this.location = data.location
       this.player1 = data.player1
       this.player2 = data.player2
       this.player3 = data.player3
       this.otherDetails = data.otherDetails
     })


  //cs photos 

     this.linkRef2 = this.fs.collection(`cships/${this.id}/csPhotos`)
     
     this.link2 = this.linkRef2.valueChanges();
  
     this.link2.subscribe(data=>{

      this.photos = data

       this.photosLenght = data.length

       if(this.photosLenght == 0 ) {

        this.nullPhotos = true

      }

    })


    this.linkRef2 = this.fs.collection(`cships/${this.id}/csOrganizers`)
     
     this.link2 = this.linkRef2.valueChanges();
  
     this.link2.subscribe(data=>{
        
      this.orgs = data

        this.orgsLenght = data.length

        if(this.orgsLenght == 0 ) {
 
        this.nullOrgs = true
 
      }
    
    })


    }

}
