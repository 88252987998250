import { Component, OnInit, OnDestroy } from '@angular/core';
import { CshipsService } from 'src/app/services/cships.service';
import { Cship } from 'src/app/interfaces/cship.interface';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-cships-cards',
  templateUrl: './cships-cards.component.html',
  styleUrls: ['./cships-cards.component.scss']
})
export class CshipsCardsComponent implements OnInit,OnDestroy {

  cship: Cship[] = []
  cshipObservable:Subscription;


  constructor(private champtionShips:CshipsService) { }

  ngOnInit() {
    this.cshipObservable = this.champtionShips.getCships().subscribe(data => {
      this.cship = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }

  ngOnDestroy() {
    this.cshipObservable.unsubscribe();
  }

}
