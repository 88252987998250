import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from'@angular/router'
import { News } from 'src/app/interfaces/news.interface';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Sponsers } from 'src/app/interfaces/sponsers';
import { Contacts } from 'src/app/interfaces/contacts';
import { PublicInfoService } from 'src/app/services/public-info.service';

@Component({
  selector: 'app-single-news',
  templateUrl: './single-news.component.html',
  styleUrls: ['./single-news.component.scss']
})
export class SingleNewsComponent implements OnInit {

  @Input() singlePage: News;

  id:string;

  linkRef: AngularFirestoreDocument<any>;

  linkRef2: AngularFirestoreCollection<any>;

  link: Observable<any>;

  sponsors :Sponsers[] =[]

  sponsorsnull: boolean = false
  
  sponsersLenght : number = null

  contacts:Contacts[]

  title:string = ''

  date:string = ''
  
  description:string= ''
  
  writerName:string= ''
  
  writerArticale:string=''
  
  avatarUrl:string=''

  photoUrl:string=''
  
  constructor(private route: ActivatedRoute,private fs: AngularFirestore, private getContacts:PublicInfoService) { }

  ngOnInit() {
   
  this.route.paramMap.subscribe(params => this.id = params.get('id'))

   
   this.linkRef = this.fs.collection('news').doc(`${this.id}`)
   
   this.link = this.linkRef.valueChanges();

   this.link.subscribe(data=>{
      this.title = data.title
      this.singlePage = data 
      this.photoUrl = data.photoUrl
      this.date = data.date
      this.description = data.description
      this.writerName = data.writerName
      this.avatarUrl = data.avatarUrl
      this.writerArticale = data.writerArticale
    })

  //sponsors

   this.linkRef2 = this.fs.collection(`news/${this.id}/sponsers`)
   
   this.link = this.linkRef2.valueChanges();

   this.link.subscribe(data=>{

    this.sponsors = data

    this.sponsersLenght = data.length

    if(this.sponsersLenght == 0) {
    
      this.sponsorsnull = true
    
    }
    
   })

   this.getContacts.getContacts().subscribe(data => this.contacts = data)
  }

}
