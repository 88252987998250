import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Cship } from 'src/app/interfaces/cship.interface';
import { CshipsService } from 'src/app/services/cships.service';
import { CsOrgs } from 'src/app/interfaces/cs-orgs';
import { LoginGuardService } from 'src/app/services/login-guard.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-cship',
  templateUrl: './add-cship.component.html',
  styleUrls: ['./add-cship.component.scss']
})
export class AddCshipComponent implements OnInit {

  headElements = ['العنوان', 'الموقع ', 'الصورة', 'الصور','المنظمون','']

  @ViewChild('mainPhoto') mainPhoto:ElementRef
  @ViewChild('gPhoto') gPhoto:ElementRef
  @ViewChild('orgAvatar') orgAvatar:ElementRef

  data: Cship[] = []

  model: NgbDateStruct;
  
  date: {year: number, month: number};

  stringDate: string = ""

  calnderUI: boolean = false

  methods = 'ودية رسمية'.split(' ');

  selectedmethod = this.methods[1];

  status: string = '';


  constructor(private addCship:CshipsService,
              private accessFalse: LoginGuardService,
              private router: Router,
              private fs:AngularFirestore,
              private calendar: NgbCalendar,
              private parserFormatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    this.addCship.getCships().subscribe(data => {
      this.data = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })    
 
  }

  on:boolean = true

  off:boolean = false

  today() {
    this.model = this.calendar.getToday()
    this.stringDate = this.parserFormatter.format(this.model);
  }

  showCalanderUI() {
    this.calnderUI = true
    this.on = false
    this.off = true
  }
  hideCalnderUI() {
    this.stringDate = this.parserFormatter.format(this.model);
    this.calnderUI = false 
    this.off = false
    this.on = true
  }

  loading: boolean = false

  done:boolean = false

  submit:boolean = true

  errorMessage:string = ''

   onChange(newMethod) {
    this.selectedmethod = newMethod;
    this.status = newMethod;
  }

  addNew(f: NgForm) {

    this.loading = true

    this.submit = false

     let title =        (<Cship>f.value).title,
        //  status =        (<Cship>f.value).status,
         age =          (<Cship>f.value).age,
         date =         (<Cship>f.value).date,
         location =     (<Cship>f.value).location,
         otherDetails = (<Cship>f.value).otherDetails,
         player1 =      (<Cship>f.value).player1,
         player2 =      (<Cship>f.value).player2,
         player3 =      (<Cship>f.value).player3,
         mainPhoto =   (<HTMLInputElement>this.mainPhoto.nativeElement).files[0]
       this.addCship.addCship(        
         title,
         this.status,
         date,
         age,
         location,
         otherDetails,
         player1,
         player2,
         player3,
         mainPhoto).then(()=>{
          this.loading = false
          this.done = true
          setTimeout(() => {
            this.done = false
            this.submit = true
          }, 4000);
         }).catch(error =>{
          this.errorMessage = error.message
        })
      }

       addPhotos(id:string) {
          this.submit = false

          document.getElementById('loadingAlert1').style.display="block";

          let gPhoto =   (<HTMLInputElement>this.gPhoto.nativeElement).files;
          
          for (let i = 0; i < gPhoto.length; i++) {
                this.addCship.addPhotos(id,gPhoto[i]).then(()=>{
                  document.getElementById('loadingAlert1').style.display="none";
                  document.getElementById('alert1').style.display="block";
                  this.submit = true
                })
                .catch(error =>{
                  this.errorMessage = error.message
                })
                if(gPhoto.length > 10000000){
                  alert("Each File should be less than 10 MB of size.");
              return;
            }
          }
       }

       loadingIcon:boolean = false
       success:boolean = false
      
       addOrganizer(f4: NgForm, id:string) {

        this.submit = false
        this.loadingIcon = true

    
         let orgName      =   (<CsOrgs>f4.value).orgName,
             orgJob       =   (<CsOrgs>f4.value).orgJob,
             orgAvatar    =   (<HTMLInputElement>this.orgAvatar.nativeElement).files[0]       
             console.log(f4)
             this.addCship.addOrgainzer(id,orgName,orgJob,orgAvatar)
             .then( () => {
               this.submit = true
               this.loadingIcon = false
               this.success= true
               setTimeout(()=>{
                 this.success = false
               },4000)
             }).catch(error =>{
               this.loadingIcon = false
               this.errorMessage = error.message
             })   
      }

      delete(index){this.addCship.delete(this.data[index].id)}

}
