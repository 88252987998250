import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DbAuthService {

  user: Observable<firebase.User>

  constructor(private afAuth: AngularFireAuth) {

    this.user = afAuth.user
   
  }

  login(email,password) {

    return this.afAuth.signInWithEmailAndPassword(email,password)
  
  }
  logout() {
    return this.afAuth.signOut()    
  }

}
