<!-- Start Provide Section -->
<section class="provide-section" style="margin-top: 80px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="provide-features">
                    <div class="row align-items-center">
                        
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="provide-box">
                                <img src="assets/imgs/gallery.png" height="100" width="100">
                                <h3>
                                    <a routerLink="/gallery" style="text-decoration: none;">الصور</a>
                                </h3>
                            </div>
                        </div>
                

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="provide-box">
                                <img src="assets/imgs/videos.png" height="100" width="100">
                                <h3>
                                    <a routerLink="/videos" style="text-decoration: none;">الفيدوهات</a>
                                </h3>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>    
</section>
<!-- End Provide Section -->