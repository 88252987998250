import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { promise } from 'protractor';
import { resolve } from 'dns';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {

  constructor(private fs: AngularFirestore, private storage: AngularFireStorage) { }

  getCarousel(){ return this.fs.collection('carousel').valueChanges() }

  getCarouselId(){ return this.fs.collection('carousel').snapshotChanges() }


  addCarousel(name:string, image:File){

    return new Promise((resolve, reject)=> {
      
    let ref = this.storage.ref('carousel/' + image.name)
    
    ref.put(image).then( ()=> {
      
      ref.getDownloadURL().subscribe(photoUrl => {
      
        this.fs.collection('carousel').add({
          name,
          photoUrl
        }).then(()=>resolve())
      })
    })
  })
}
  delete(id){ return this.fs.doc(`carousel/${id}`).delete() }
}
