import { Component, OnInit } from '@angular/core';
import { recervationForm } from 'src/app/interfaces/recervation.interface';
import { TrainingsService } from 'src/app/services/trainings.service';

@Component({
  selector: 'app-trainings-recev',
  templateUrl: './trainings-recev.component.html',
  styleUrls: ['./trainings-recev.component.scss']
})
export class TrainingsRecevComponent implements OnInit {

  constructor(private view: TrainingsService) { }
  
  data: recervationForm[] = []


  headElements = ['الأسم', 'البريد الالكتروني', 'الدورة', 'رقم الهاتف','تفاصيل أخري',''];

  ngOnInit(): void {
    this.view.getRecervations().subscribe(data => {
      this.data = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }
  delete(index) {
    this.view.deleteRecervation(this.data[index].id)
  }

}
