<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<div class="container">
    <div class="head">
        <h2>أختر الدورة</h2>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="box">
                <!-- <div class="overlay"></div> -->
                <a href="" routerLink="/trainings-older" style="text-decoration: none;">للكبار</a>
            </div>
        </div>
        <div class="col-md-3">
            <div class="box">
                <!-- <div class="overlay"></div> -->
                <a href="" routerLink="/trainings-young" style="text-decoration: none;">للصغار</a>
            </div>            
        </div>
        <div class="col-md-3">
            <div class="box">
                <!-- <div class="overlay"></div> -->
                <a  href=""routerLink="/trainings-pro" style="text-decoration: none;">للمحترفين</a>
            </div>            
        </div>
        <div class="col-md-3">
            <div class="box">
                <!-- <div class="overlay"></div> -->
                <a href="" routerLink="/trainings-beginner" style="text-decoration: none;">للمبتدأين</a>
            </div>            
        </div>
    </div>
</div>
  <!-- footer-->
  <app-footer></app-footer>
  <!-- /footer-->