import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cships',
  templateUrl: './cships.component.html',
  styleUrls: ['./cships.component.scss']
})
export class CshipsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
