import { Component, OnInit } from '@angular/core';
import { Establishers } from 'src/app/interfaces/establishers';
import { EstablishersService } from 'src/app/services/establishers.service';

@Component({
  selector: 'app-parallax-section',
  templateUrl: './parallax-section.component.html',
  styleUrls: ['./parallax-section.component.scss']
})
export class ParallaxSectionComponent implements OnInit {

  constructor(private estServices:EstablishersService) { }

  establisher: Establishers[]

  ngOnInit(): void {
    this.estServices.getEstablishers().subscribe(data => this.establisher = data)
  }

}
