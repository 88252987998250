<div class="message-section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xm-12">
        <div class="uni-msg">
          <div class="half-square"></div>
            <h3>رسالة الأتحاد</h3>
            <p *ngFor="let info of message">
              {{info.message}}
            </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xm-12">
        <div class="uni-msg-img">
            <div class="square"></div>
            <img src="assets/imgs/msg.png" alt="رسالة الاتحاد">
        </div>
      </div>
    </div>
  </div>
</div>
