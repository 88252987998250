import { Component, OnInit } from '@angular/core';
import { joinForm } from 'src/app/interfaces/join.interface';
import { JoinusService } from 'src/app/services/joinus.service';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit {

  constructor(private view: JoinusService) { }
  
  data: joinForm[] = []


  headElements = ['الأسم', 'الهاتف', 'البريد الالكتروني', 'الرسالة','',''];

  ngOnInit(): void {
    this.view.getJoin().subscribe(data => {
      this.data = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }
  delete(index) {
    this.view.deleteJoin(this.data[index].id)
  }

}
