<!-- Start Blog Section -->
<section class="blog-section ptb-100">
  <div class="container">
    <div class="blog-title">
        <h2>أخر البطولات</h2>
        <p>كل ما هو جديد عن البطولات</p>
    </div>
    <mdb-carousel [animation]="'slide'">

      <mdb-carousel-item>
        <div class="col-lg-4 col-md-4">
          <div class="single-blog-post">
              <figure>
                  <a href="#">
                      <img src="assets/imgs/news-1.png" alt="blog-img" height="240">
                  </a>
              </figure>

              <div class="blog-post-content">
                  <span>22/2/2019</span>
                  <h3><a routerLink="/single-cship">عنوان البطولة</a></h3>
                  <div class="blog-btn-cshpis">
                    <img src="assets/imgs/troffi.png" height="30">
                    <p class="csp">رسمية</p>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-lg-4 col-md-4">
        <div class="single-blog-post">
            <figure>
                <a href="#">
                  <img src="assets/imgs/news-2.png" alt="blog-img" height="240">
                </a>
            </figure>

            <div class="blog-post-content">
                <span>22/2/2019</span>
                <h3><a routerLink="/single-cship">عنوان البطولة</a></h3>
                <div class="blog-btn-cshpis">
                  <img src="assets/imgs/troffi.png" height="30">
                    <p class="csp">رسمية</p>
                </div>
            </div>
        </div>
      </div>

    <div class="col-lg-4 col-md-4">
      <div class="single-blog-post">
          <figure>
              <a href="#">
                <img src="assets/imgs/news-3.png" alt="blog-img" height="240">
              </a>
          </figure>

          <div class="blog-post-content">
              <span>22/2/2019</span>
              <h3><a routerLink="/single-cship">عنوان البطولة</a></h3>
              <div class="blog-btn-cshpis">
                <img src="assets/imgs/troffi.png" height="30">
                    <p class="csp">رسمية</p>
              </div>
          </div>
      </div>
    </div>
      </mdb-carousel-item>


      <mdb-carousel-item>
        <div class="col-lg-4 col-md-4">
          <div class="single-blog-post">
              <figure>
                  <a href="#">
                    <img src="assets/imgs/news-1.png" alt="blog-img" height="240">
                  </a>
              </figure>

              <div class="blog-post-content">
                  <span>22/2/2019</span>
                  <h3><a routerLink="/single-cship">عنوان البطولة</a></h3>
                  <div class="blog-btn-cshpis">
                    <span><img src="assets/imgs/troffi.png" height="30"> <span>رسمية</span></span>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-lg-4 col-md-4">
        <div class="single-blog-post">
            <figure>
                <a href="#">
                  <img src="assets/imgs/news-2.png" alt="blog-img" height="240">
                </a>
            </figure>

            <div class="blog-post-content">
                <span>22/2/2019</span>
                <h3><a routerLink="/single-cship">عنوان البطولة</a></h3>
                <div class="blog-btn-cshpis">
                  <img src="assets/imgs/troffi.png" height="30">
                    <p class="csp">رسمية</p>
                </div>
            </div>
        </div>
      </div>

    <div class="col-lg-4 col-md-4">
      <div class="single-blog-post">
          <figure>
              <a href="#">
                <img src="assets/imgs/news-3.png" alt="blog-img" height="240">
              </a>
          </figure>

          <div class="blog-post-content">
              <span>22/2/2019</span>
              <h3><a routerLink="/single-cship">عنوان البطولة</a></h3>
              <div class="blog-btn-cshpis">
                <img src="assets/imgs/troffi.png" height="30">
                    <p class="csp">رسمية</p>
              </div>
          </div>
      </div>
    </div>
      </mdb-carousel-item>


      <mdb-carousel-item>
        <div class="col-lg-4 col-md-4">
          <div class="single-blog-post">
              <figure>
                  <a href="#">
                    <img src="assets/imgs/news-1.png" alt="blog-img" height="240">
                  </a>
              </figure>

              <div class="blog-post-content">
                  <span>22/2/2019</span>
                  <h3><a routerLink="/single-cship">عنوان البطولة</a></h3>

                  <div class="blog-btn-cshpis">
                    <img src="assets/imgs/troffi.png" height="30">
                      <p class="csp">رسمية</p>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-lg-4 col-md-4">
        <div class="single-blog-post">
            <figure>
                <a href="#">
                  <img src="assets/imgs/news-2.png" alt="blog-img" height="240">
                </a>
            </figure>

            <div class="blog-post-content">
                <span>22/2/2019</span>
                <h3><a routerLink="/single-cship">عنوان البطولة</a></h3>
                <div class="blog-btn-cshpis">
                  <img src="assets/imgs/troffi.png" height="30">
                    <p class="csp">رسمية</p>
                </div>
            </div>
        </div>
      </div>

    <div class="col-lg-4 col-md-4">
      <div class="single-blog-post">
          <figure>
              <a href="#">
                <img src="assets/imgs/news-3.png" alt="blog-img" height="240">
              </a>
          </figure>

          <div class="blog-post-content">
              <span>22/2/2019</span>
              <h3><a routerLink="/single-cship">عنوان البطولة</a></h3>
              <div class="blog-btn-cshpis">
                <img src="assets/imgs/troffi.png" height="30">
                    <p class="csp">رسمية</p>
              </div>
          </div>
      </div>
    </div>
      </mdb-carousel-item>
    </mdb-carousel>
   </div>
  </section>
