import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService {

  access:number = 1
  
}
