import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Old } from 'src/app/interfaces/old.interface';
import { Amature } from 'src/app/interfaces/amature.interface';
import { Pro } from 'src/app/interfaces/pro.interface';
import { Young } from 'src/app/interfaces/young.interface';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { TrainingsService } from 'src/app/services/trainings.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-train',
  templateUrl: './add-train.component.html',
  styleUrls: ['./add-train.component.scss']
})
export class AddTrainComponent implements OnInit {

  old: Old[] = []
  
  amature:Amature[] = []
 
  pro:Pro[] = []
 
  young:Young[] =[]

  headElements = [ 'عنوان الدورة', 'التاريخ', 'السن','المدة','المدرب','الصورة',''];

  errorMessage: string = ""

  submit:boolean = true

  model: NgbDateStruct;
  
  date: {year: number, month: number};

  stringDate: string = ""

  calnderUI: boolean = false

  @ViewChild('photoUrlPro') photoUrlPro:ElementRef
  // @ViewChild('orgAvatar1Pro') orgAvatar1Pro:ElementRef
  // @ViewChild('orgAvatar2Pro') orgAvatar2Pro:ElementRef
  // @ViewChild('orgAvatar3Pro') orgAvatar3Pro:ElementRef

   @ViewChild('photoUrlAmature') photoUrlAmature:ElementRef
  // @ViewChild('image2') trainImage:ElementRef
  // @ViewChild('image3') cshipImage:ElementRef
  // @ViewChild('image4') playerImage:ElementRef

   @ViewChild('photoUrlYoung') photoUrlYoung:ElementRef
  // @ViewChild('image2') trainImage:ElementRef
  // @ViewChild('image3') cshipImage:ElementRef
  // @ViewChild('image4') playerImage:ElementRef

   @ViewChild('photoUrlOld') photoUrlOld:ElementRef
  // @ViewChild('image2') trainImage:ElementRef
  // @ViewChild('image3') cshipImage:ElementRef
  // @ViewChild('image4') playerImage:ElementRef

  constructor(private addGTraining: TrainingsService, config: NgbTabsetConfig,private calendar: NgbCalendar,
    private parserFormatter: NgbDateParserFormatter) {
    config.justify = 'center';
    config.type = 'pills';
   }

  ngOnInit(): void {
    this.addGTraining.getProTrain().subscribe(data => {
      this.pro = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.addGTraining.getYoungTrain().subscribe(data => {
      this.young = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.addGTraining.getOldTrain().subscribe(data => {
      this.old = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })

    this.addGTraining.getBeginnersTrain().subscribe(data => {
      this.amature = data.map(element => {
        return {
          id:element.payload.doc.id,
          ...element.payload.doc.data() as {}
        }
      })
    })
  }
  
  on:boolean = true

  off:boolean = false

  today() {
    this.model = this.calendar.getToday()
    this.stringDate = this.parserFormatter.format(this.model);
  }

  showCalanderUI() {
    this.calnderUI = true
    this.on = false
    this.off = true
  }
  hideCalnderUI() {
    this.stringDate = this.parserFormatter.format(this.model);
    this.calnderUI = false 
    this.off = false
    this.on = true
  }

  addNewPro(f: NgForm) {

    this.submit = false

    document.getElementById('loadingAlert1').style.display="block";

    let title = (<Pro>f.value).title,
    period = (<Pro>f.value).period,
    price = (<Pro>f.value).price,
    location = (<Pro>f.value).location,
    age = (<Pro>f.value).age,
    date = (<Pro>f.value).date,
    coachName = (<Pro>f.value).coachName,
    coachDetails = (<Pro>f.value).coachDetails,
    otherDetails = (<Pro>f.value).otherDetails,
    // orgName1 = (<Pro>f.value).orgName1,
    // orgJob1 = (<Pro>f.value).orgJob1,
    // orgName2 = (<Pro>f.value).orgName2,
    // orgJob2 = (<Pro>f.value).orgJob2,
    // orgName3 = (<Pro>f.value).orgName3,
    // orgJob3 = (<Pro>f.value).orgJob3,
    photoUrl = (<HTMLInputElement>this.photoUrlPro.nativeElement).files[0];
    // orgAvatar1 = (<HTMLInputElement>this.orgAvatar1Pro.nativeElement).files[0],
    // orgAvatar2 = (<HTMLInputElement>this.orgAvatar2Pro.nativeElement).files[0],
    // orgAvatar3 = (<HTMLInputElement>this.orgAvatar3Pro.nativeElement).files[0];
      this.addGTraining.addProTrain(
        title,
        period,
        price,
        coachName,
        coachDetails,
        date,
        age,
        location,
        otherDetails,
        photoUrl,
        // orgName1,
        // orgAvatar1,
        // orgJob1,
        // orgName2,
        // orgAvatar2,
        // orgJob2,
        // orgName3,
        // orgAvatar3,
        // orgJob3
        ).then(()=>{
          document.getElementById('loadingAlert1').style.display="none";
          document.getElementById('alert1').style.display="block";
          this.submit = true
        }).catch(error=>{
          this.errorMessage = error.message
        })
    }
    deleteProTrain(index){ this.addGTraining.deleteProTrain(this.pro[index].id)}

    addNewAmature(f2: NgForm){
      
      this.submit = false

      document.getElementById('loadingAlert2').style.display="block";

      let title = (<Amature>f2.value).title,
      period = (<Amature>f2.value).period,
      price = (<Amature>f2.value).price,
      location = (<Amature>f2.value).location,
      age = (<Amature>f2.value).age,
      date = (<Amature>f2.value).date,
      coachName = (<Amature>f2.value).coachName,
      coachDetails = (<Amature>f2.value).coachDetails,
      otherDetails = (<Amature>f2.value).otherDetails,
      photoUrl = (<HTMLInputElement>this.photoUrlAmature.nativeElement).files[0];
        this.addGTraining.addBeginnersTrain(
          title,
          period,
          price,
          coachName,
          coachDetails,
          date,
          age,
          location,
          otherDetails,
          photoUrl,
          ).then(()=>{
            document.getElementById('loadingAlert2').style.display="none";
            document.getElementById('alert2').style.display="block";
            this.submit = true
          }).catch(error=>{
            this.errorMessage = error.message
          })      
    }
    deleteAmatureTrain(index){ this.addGTraining.deleteBeginnersTrain(this.amature[index].id)}

    addNewYoung(f3:NgForm){
      
      this.submit = false

      document.getElementById('loadingAlert3').style.display="block";

      let title = (<Young>f3.value).title,
      period = (<Young>f3.value).period,
      price = (<Young>f3.value).price,
      location = (<Young>f3.value).location,
      age = (<Young>f3.value).age,
      date = (<Young>f3.value).date,
      coachName = (<Young>f3.value).coachName,
      coachDetails = (<Young>f3.value).coachDetails,
      otherDetails = (<Young>f3.value).otherDetails,
      photoUrl = (<HTMLInputElement>this.photoUrlYoung.nativeElement).files[0];
        this.addGTraining.addYoungTrain(
          title,
          period,
          price,
          coachName,
          coachDetails,
          date,
          age,
          location,
          otherDetails,
          photoUrl,
          ).then(()=>{
            document.getElementById('loadingAlert3').style.display="none";
            document.getElementById('alert3').style.display="block";
            this.submit = true
          }).catch(error=>{
            this.errorMessage = error.message
          })      
    }
    deleteYoungTrain(index){ this.addGTraining.deleteYoungTrain(this.young[index].id)}

    addNewOld(f4:NgForm){
      
      this.submit = false
      
      document.getElementById('loadingAlert4').style.display="block";

      let title = (<Young>f4.value).title,
      period = (<Young>f4.value).period,
      price = (<Young>f4.value).price,
      location = (<Young>f4.value).location,
      age = (<Young>f4.value).age,
      date = (<Young>f4.value).date,
      coachName = (<Young>f4.value).coachName,
      coachDetails = (<Young>f4.value).coachDetails,
      otherDetails = (<Young>f4.value).otherDetails,
      photoUrl = (<HTMLInputElement>this.photoUrlOld.nativeElement).files[0];
        this.addGTraining.addOldTrain(
          title,
          period,
          price,
          coachName,
          coachDetails,
          date,
          age,
          location,
          otherDetails,
          photoUrl,
          ).then(()=>{
            document.getElementById('loadingAlert4').style.display="none";
            document.getElementById('alert4').style.display="block";
            this.submit = true
          }).catch(error=>{
            this.errorMessage = error.message
          })
    }
    deleteOldTrain(index){ this.addGTraining.deleteOldTrain(this.old[index].id)}
}
