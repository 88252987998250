import { Component, OnInit } from '@angular/core';
import { Message } from 'src/app/interfaces/message';
import { PublicInfoService } from 'src/app/services/public-info.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private getMessages:PublicInfoService) { }

  presentaition: Message[]

  goal: Message[]

  ngOnInit(): void {
    
    this.getMessages.getPresentation().subscribe(data => this.presentaition = data)

    this.getMessages.getGoal().subscribe(data => this.goal = data)


  }

}
