<div class="footer">
  <img src="assets/imgs/logo.png" alt="الأتحاد السعودي للجودو" class="g-logo">
  <h5>الأتحاد السعودي للجودو</h5>
  <div class="container logos-container">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <img src="assets/imgs/lo1.png" width="100">
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <img src="assets/imgs/lo3.png" width="100">
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <img src="assets/imgs/lo2.png" width="100">
      </div>
    </div>
  </div>
  <div class="row platforms" *ngFor="let info of contacts">
    <div class="col-md-1">
      <div class="facebook">
          <a href="{{info.facebookUrl}}">
             <i class="fab fa-facebook"></i>
          </a>
      </div>
    </div>
    <div class="col-md-1">
      <div class="whatsapp">
        <a href="{{info.whatsappUrl}}">
          <i class="fab fa-whatsapp"></i>
        </a>
      </div>
    </div>
    <div class="col-md-1">
      <div class="twiiter">
          <a href="{{info.twitterUrl}}">
            <i class="fab fa-twitter"></i>
          </a>
      </div>
    </div>
    <div class="col-md-1">
      <div class="snap">
        <a href="{{info.snapChatUrl}}">
          <i class="fab fa-snapchat-ghost"></i>    
        </a>
      </div>
    </div>
    <div class="col-md-1">
      <div class="youtube">
        <a href="{{info.youtubeUrl}}">
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>
  </div>
  
</div>
<div class="sub-footer">
  <div class="row" style="justify-content: center;">
    <div class="col-md-12">
      <a href="https://apps.apple.com/eg/app/%D8%A7%D9%84%D8%A7%D8%AA%D8%AD%D8%A7%D8%AF-%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A-%D9%84%D9%84%D8%AC%D9%88%D8%AF%D9%88/id1540464201">
              <img src="assets/imgs/apple.png" alt="apple store" width="165">
      </a>
    </div>
  </div>
</div>
