import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';


@Injectable({
  providedIn: 'root'
})
export class CshipsService {

  constructor(private fs: AngularFirestore, private storage: AngularFireStorage) { }

  getCships() { return this.fs.collection('cships').snapshotChanges() }

  addCship(
    title: string,
    status: string,
    date: string,
    age: number,
    location: string,
    otherDetails: string,
    player1: string,
    player2: string,
    player3: string,
    mainPhoto: File
  ) {
    return new Promise((resolve, reject) => {
      let ref = this.storage.ref('cships/' + mainPhoto.name)
      ref.put(mainPhoto).then(() => {
        ref.getDownloadURL().subscribe(mainPhoto => {
          this.fs.collection('cships').add({
            title,
            status,
            date,
            age,
            location,
            otherDetails,
            player1,
            player2,
            player3,
            mainPhoto
          }).then(() => resolve())
        })
      })
    })
  }


  addPhotos(
    id: string,
    gPhoto: File,
  ) {
    return new Promise((resolve, reject) => {
      let ref = this.storage.ref('cships/' + gPhoto.name)
      ref.put(gPhoto).then(() => {
        ref.getDownloadURL().subscribe(gPhoto => {
          this.fs.collection(`cships/${id}/csPhotos`).add({
            gPhoto,
          }).then(() => resolve())
        })
      })
    })
  }

  addOrgainzer(
    id:string,
    orgName:string,
    orgJob:string,
    orgAvatar:File,
  ){
    return new Promise((resolve, reject) => {
      let ref = this.storage.ref('cShipsOrgainzers/' + orgAvatar.name)
      ref.put(orgAvatar).then(() => {
        ref.getDownloadURL().subscribe(orgAvatar => {
          this.fs.collection(`cships/${id}/csOrganizers`).add({
            orgName,
            orgJob,
            orgAvatar,
          }).then(() => resolve())
        })
      })
    })
  }
  
  delete(id) { return this.fs.doc(`cships/${id}`).delete() }

}
