<body>

<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<!-- carousel -->
<app-carousel></app-carousel>
<!-- /carousel -->

<!--latest news carousel-->
<app-cards-carousel></app-cards-carousel>
<!--/latest cards carousel-->

<!--uni message-->
<app-message></app-message>
<!--/uni message-->


<!--founders section-->
<app-parallax-section></app-parallax-section>
<!--/founders section-->

<!--provider section-->
 <app-provider></app-provider>
<!--/provider section-->

<!--counter section-->
<app-counter></app-counter>
<!--/counter section-->

<!--explore section-->
<app-explore></app-explore>
<!--/explore section-->

<!--gallery section-->
<!-- <app-lightbox-gallery></app-lightbox-gallery> -->
<!--/gallery section-->

<!--contacts section-->
<!-- <app-contacts></app-contacts> -->
<!--/contacts section-->

<!-- footer-->
<app-footer></app-footer>
<!-- /footer-->
  
</body>
