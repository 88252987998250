<header class="header" role="banner">
    <h1 class="logo">
        <a class="navbar-brand" routerLink="/"><span>الأتحاد السعودي للجودو</span><img src="assets/imgs/logo.png" alt="شعار الأتحاد"></a>
    </h1>
    <div class="nav-wrap">
      <nav class="main-nav" role="navigation">
        <ul class="unstyled list-hover-slide">
          <li><a routerLink="/add-carousel" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">اللوحات المتحركة</a></li>
          <li><a routerLink="/add-news" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">الأخبار</a></li>
          <li><a routerLink="/add-videos" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">الفيدوهات</a></li>
          <li><a routerLink="/add-gallery" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">أستديو الصور</a></li>
          <li><a routerLink="/add-cships" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">البطولات</a></li>
          <li><a routerLink="/add-trainings" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">الدورات</a></li>
          <li><a routerLink="/add-sections" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">الأقسام</a></li>
          <li><a routerLink="/view-receivervations" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">حجوزات الدورات</a></li>
          <li><a routerLink="/view-joinus" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"> أنضم ألينا </a></li>
          <li><a routerLink="/edit-public-info" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"> معلومات </a></li>
        </ul>
      </nav>
    </div>
  </header>