import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute } from'@angular/router';
import { Training } from 'src/app/interfaces/trainings.interface';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { TrainingsService } from 'src/app/services/trainings.service';
import { recervationForm } from 'src/app/interfaces/recervation.interface';



@Component({
  selector: 'app-single-training',
  templateUrl: './single-training.component.html',
  styleUrls: ['./single-training.component.scss']
})
export class SingleTrainingComponent implements OnInit {

  @Input() singlePage: Training

  id:string;

  linkRef: AngularFirestoreDocument<any>;

  link: Observable<any>;

  training: recervationForm[] = []

  title:string = ''

  photoUrl:string = ''

  otherDetails:string = ''

  date:string = ''

  age:number

  location:string = ''

  price:number = null

  period:string = ''

  coachName:string = ''

  coachDetails:string = ''
  
  trainingTitle:string = ''

  constructor(private route: ActivatedRoute,private fs: AngularFirestore, private addResv:TrainingsService) { }

  ngOnInit(): void {
    
    this.route.paramMap.subscribe(params => this.id = params.get('id'))
  
     //Young
     this.linkRef = this.fs.collection('youngTrainings').doc(`${this.id}`)
     
     this.link = this.linkRef.valueChanges();
  
     this.link.subscribe(data=> {
        this.singlePage = data
        this.title = data.title
        this.age = data.age
        this.location = data.location
        this.price = data.price
        this.coachName = data.coachName
        this.coachDetails = data.coachDetails
        this.otherDetails = data.otherDetails
        this.date = data.date
        this.photoUrl = data.photoUrl
        this.period = data.period
        this.trainingTitle = data.title
    });

    //Pro
     this.linkRef = this.fs.collection('proTrainings').doc(`${this.id}`)
     
     this.link = this.linkRef.valueChanges();
  
     this.link.subscribe(data=> {
      this.singlePage = data
      this.title = data.title
      this.age = data.age
      this.location = data.location
      this.price = data.price
      this.coachName = data.coachName
      this.coachDetails = data.coachDetails
      this.otherDetails = data.otherDetails
      this.date = data.date
      this.photoUrl = data.photoUrl
      this.period = data.period
      this.trainingTitle = data.title
  });

    //Old
    this.linkRef = this.fs.collection('oldTrainings').doc(`${this.id}`)
     
    this.link = this.linkRef.valueChanges();
 
    this.link.subscribe(data=> {
      this.singlePage = data
      this.title = data.title
      this.age = data.age
      this.location = data.location
      this.price = data.price
      this.coachName = data.coachName
      this.coachDetails = data.coachDetails
      this.otherDetails = data.otherDetails
      this.date = data.date
      this.photoUrl = data.photoUrl
      this.period = data.period
      this.trainingTitle = data.title
  });     

    //Amateur
    this.linkRef = this.fs.collection('amateurTrainings').doc(`${this.id}`)
  
    this.link = this.linkRef.valueChanges();
  
    this.link.subscribe(data=> {
      this.singlePage = data
      this.title = data.title
      this.age = data.age
      this.location = data.location
      this.price = data.price
      this.coachName = data.coachName
      this.coachDetails = data.coachDetails
      this.otherDetails = data.otherDetails
      this.date = data.date
      this.photoUrl = data.photoUrl
      this.period = data.period
      this.trainingTitle = data.title
  });

  }

  submit: boolean = true
  
  loading: boolean = false
  
  done: boolean = false
  
  errorMessage:string = ''

  book(form:NgForm) {

    this.submit = false

    this.loading = true

    let name = (<recervationForm>form.value).name,
    
    email = (<recervationForm>form.value).email,

    trainingTitle = (<recervationForm>form.value).trainingTitle,

    phone = (<recervationForm>form.value).phone,

    otherDetails = (<recervationForm>form.value).otherDetails;

    this.addResv.addReservation(
      name,
      email,
      trainingTitle,
      phone,
      otherDetails).then(()=>{
        this.done = true
        this.loading = false
      }).catch(error=>{
        console.log(error)
        this.loading = false
        this.errorMessage = error.message
      })
   }

  showConfirmation(){
  }
}
