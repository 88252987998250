<app-get-access></app-get-access>
<app-sidebar></app-sidebar>
<div class="ct">
  <button type="button" mdbBtn class="relative waves-light custom" (click)="basicModal.show()" mdbWavesEffect>أضافة جديد</button>
<div class="container">
    <table mdbTable>
        <thead class="custom white-text">
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{head}} </th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let details of data; index as i">
            <th scope="row">{{details.title}}</th>
            <td>{{details.location}}</td>
            <td> <img [src]="details.mainPhoto" width="60" height="60"></td>

            <td> <button class="btn btn-info" (click)="basicModal3.show()" style="border-radius: 10px;">أضافة</button> </td>
            <div mdbModal style="overflow-y: auto" #basicModal3="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" >
                <div class="modal-content">
                  <div class="modal-body" style="overflow-x: hidden !important;">
                    
                     <form #f2="ngForm" (ngSubmit)="addPhotos(details.id)">
                    
                      <div class="md-form">
    
                      <h4 style="text-align: center; padding: 10px;">صور البطولة</h4>
                        <div class="row" style="justify-content: center;">
                            <div class="col-md-4">
                              <div class="button-wrapper">
                                  <span class="label">
                                      الصور
                                  </span>
                                    <input type="file" multiple id="upload" class="upload-box" placeholder="Upload File" ngModel name="gPhoto" #gPhoto required>
                                <p class="ps-warn">*كل صورة يجيب أن يكون حجمها أقل من 10 ميجابيت*</p>
                                  </div>
                            </div>
                        </div>

                        <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 35.1%;" id="loadingAlert1">              
                        
                        <div class="alert alert-success" role="alert" style="display: none;" id="alert1">
                          تم التحميل بنجاح
                        </div>

                        <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                          يوجد خطأ ما!الرجاء المحاولة مرة أخري
                         <br>
                          {{errorMessage}}
                        </div>

                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal3.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
                        <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f2.invalid" style="border-radius: 10px;" *ngIf="submit == true">
                      
                      </div>
                      </div>
                     </form>
                  </div>
                </div>
              </div>
            </div>

            <td> <button class="btn btn-info" (click)="basicModal4.show()" style="border-radius: 10px;">أضافة</button></td>
            <div mdbModal style="overflow-y: auto" #basicModal4="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" >
                <div class="modal-content">
                  <div class="modal-body" style="overflow-x: hidden !important;">
                    
                    <form #f4="ngForm" (ngSubmit)="addOrganizer(f4,details.id)">
                    
                   <h4 style="text-align: center; padding: 10px;"> المنظمون</h4>
            
                   <p class="ps-warn2"> يمكنك أضافة أكثر من منظم بعد الأنتهاء من التحميل الأول</p>

                   <div style="padding: 10px; border-top: .2px solid #DFDBD9;">

                    <div class="md-form">
                    
                        <input mdbInput type="text" id="orgName" class="form-control cc" ngModel name='orgName' required> 
                    
                        <label for="orgName" class="label"> أسم المنظم </label>
                    
                      </div>

                      <div class="md-form">
                    
                        <input mdbInput type="text" id="orgJob" class="form-control cc" ngModel name='orgJob' required> 
                    
                        <label for="orgJob" class="label"> وظيفة المنظم </label>
                    
                      </div>
                      <div class="button-wrapper">
            
                        <span class="label" style="padding-right: 37px;">
                            صورة المنظم 
                        </span>
                    
                        <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="orgAvatar" #orgAvatar required>
                    
                    </div>
                  </div>
            
                  <img src="assets/imgs/loading.gif" width="165" height="120" style="margin: 0px 35.1%;" *ngIf="loadingIcon == true">
                                
                  <div class="alert alert-success" role="alert" *ngIf="success == true">
                    تم التحميل بنجاح
                  </div>

                  <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                    يوجد خطأ ما!الرجاء المحاولة مرة أخري
                   <br>
                    {{errorMessage}}
                  </div>

                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal4.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
                        <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f4.invalid || submit == false " style="border-radius: 10px;">
                      
                      </div>
            
                </form>
                  </div>
                </div>
              </div>
            </div>

            <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
            <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-body" style="text-align: center;">
                    <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                    
                    <p class="pp"> هل تريد تأكيد الحزف</p>
            
                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
                        <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="delete(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>            
          </tr>
        </tbody>
      </table>  
    </div>
    <div class="ct2"></div>
</div>

<div mdbModal style="overflow-y: auto" #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" >
    <div class="modal-content">
      <div class="modal-body" style="overflow-x: hidden !important;">
        
        <form #f="ngForm" (ngSubmit)="addNew(f)">
        
          <div class="md-form">
        
            <input mdbInput type="text" id="title" class="form-control cc" ngModel name='title' required> 
        
            <label for="title" class="label">عنوان البطولة </label>
        
          </div>

          <div class="md-form">
        
            <div class="md-form">
        
              <!-- <input mdbInput type="text" id="title" class="form-control cc" ngModel name='status'  placeholder="ودية أم رسمية" required>  -->
              <p class="label">حالة البطولة </p>

              <select id="status" class="select-css " [ngModel]="selectedmethod" (ngModelChange)="onChange($event)" [ngModelOptions]="{standalone: true}" required>
                      <option [ngValue]="i" *ngFor="let i of methods">{{i}}</option>
              </select>

          
            </div>
        
          </div>

          <div class="md-form">
        
            <input mdbInput type="text" id="player1" class="form-control cc" ngModel name='player1' required> 
        
            <label for="player1" class="label">المركز الأول  </label>
        
          </div>
        
          <div class="md-form">
        
            <input mdbInput type="text" id="player2" class="form-control cc" ngModel name='player2' required> 
        
            <label for="player2" class="label">المركز الثاني</label>
        
          </div>
          <div class="md-form">
        
            <input mdbInput type="text" id="player3" class="form-control cc" ngModel name='player3' required> 
        
            <label for="player3" class="label">المركز الثالث  </label>
        
          </div>
          <div class="md-form">
        
            <input mdbInput type="text" id="location" class="form-control cc" ngModel name='location' required> 
        
            <label for="location" class="label">الموقع  </label>
        
          </div>
          <div class="md-form">
        
            <input mdbInput type="number" id="age" class="form-control cc" ngModel name='age' required> 
        
            <label for="age" class="label">السن  </label>
        
          </div>
          <div class="md-form">
      
            <ngb-datepicker  [(ngModel)]="model" (navigate)="date = $event.next" *ngIf="calnderUI == true" [ngModelOptions]="{standalone: true}"></ngb-datepicker>

             <input mdbInput type="text" id="datem" class="form-control cc" [(ngModel)]="stringDate" name='date' required>

             <div class="date-options">
            
               <mdb-icon fas icon="calendar-day" (click)="today()"></mdb-icon>

               <mdb-icon far icon="calendar-alt" (click)="showCalanderUI()" *ngIf="on == true"></mdb-icon>
             
               <mdb-icon far icon="times-circle" (click)="hideCalnderUI()" *ngIf="off == true"></mdb-icon>               
            
              </div>
      
             <label for="datem" class="label">التاريخ</label>

          </div>
          <div class="md-form">
            
            <textarea type="text" id="otherDetails" class="md-textarea form-control cc2" ngModel name='otherDetails' mdbInput required></textarea>
            
            <label for="otherDetails" class="cc">تفاصيل أخري</label>
          
          </div>

          <h4 style="text-align: center; padding: 10px;">الصورة الرئيسية</h4>
          <div class="row" style="justify-content: center;">
          
         <div class="col-md-4">

          <div class="button-wrapper">

            <span class="label">
              الصورة 
            </span>
        
            <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="mainPhoto" #mainPhoto required>
        
        </div>
          
        </div>

      </div>

      <img src="assets/imgs/loading.gif" width="165" height="120" style="margin: 0px 35.1%;" *ngIf="loading == true">
                    
      <div class="alert alert-success" role="alert" *ngIf="done == true">
        تم التحميل بنجاح
      </div>

      <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
        يوجد خطأ ما!الرجاء المحاولة مرة أخري
       <br>
        {{errorMessage}}
      </div>

          <div class="modal-footer">
            
            <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
            
            <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f.invalid || submit == false" style="border-radius: 10px;">
          
          </div>

    </form>
      </div>
    </div>
  </div>
</div>