<!--Navbar-->
<app-navbar></app-navbar>
<!-- /Navbar-->

<!-- Page banner -->
<section class="page-banner">
  <div class="container">
      <div class="page-banner-content">
          <h2>أحدث اخبار الأتحاد</h2>
      </div>
  </div>
</section>
<!-- End Page banner -->

<!--latest news carousel-->
<!-- <app-cards-carousel></app-cards-carousel> -->
<!--/latest cards carousel-->

<!--latest news cards-->
<app-news-cards></app-news-cards>
<!--/latest news cards-->

  <!-- footer-->
  <app-footer></app-footer>
  <!-- /footer-->