import { Component, OnInit } from '@angular/core';
import { Contacts } from 'src/app/interfaces/contacts';
import { PublicInfoService } from 'src/app/services/public-info.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  constructor(private getContacts: PublicInfoService) { }

  contacts:Contacts[]

  ngOnInit(): void {
    this.getContacts.getContacts().subscribe(data => this.contacts = data)
  }

}
