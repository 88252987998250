
<div class="join-us">
    <!-- Start Signin Area -->
    <div class="signin-section ptb-100">
        <div class="container">
            <div class="signup-form">
                <h3> تسجيل الدخول</h3>
                <form #f="ngForm" (ngSubmit)="access(f)">
                    <div class="row">

                        <div class="col-lg-12">
                            <div class="form-group">
                                <input ngModel name="email" #email="ngModel" type="email" class="form-control" placeholder="البريد الألكتروني" required email style="text-align: center;">
                                <p *ngIf="email.errors?.required && email.touched">الرجاء أدخال عنوان البريد الألكتروني</p>
                                <p *ngIf="email.errors?.email && email.touched"> الرجاء أدخال بريد الأكتروني صحيح</p>                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <input ngModel name="password" #password="ngModel" class="form-control" type="password" placeholder="كلمة المرور" style="text-align: center;" required>
                                <p *ngIf="password.errors?.required && password.touched">الرجاءأدخال المرور</p>
                           </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="send-btn" *ngIf="submit == true">
                                <input type="submit" value="دخول" [disabled]="f.invalid">
                            </div>
                            <div class="loading-div" *ngIf="loading == true">
                                <img src="assets/imgs/loading.gif" width="150">
                            </div>
                        </div>

                        <div class="alert alert-danger" role="alert" *ngIf="errr !== ''">
                            {{errr}}
                        </div>
                    
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- End Signin Area -->
</div>