import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pro-train',
  templateUrl: './pro-train.component.html',
  styleUrls: ['./pro-train.component.scss']
})
export class ProTrainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
