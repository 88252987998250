<app-get-access></app-get-access>
<app-sidebar></app-sidebar>
<div class="ct">
  <button type="button" mdbBtn class="relative waves-light custom" (click)="basicModal.show()" mdbWavesEffect>أضافة جديد</button>
<div class="container">
    <table mdbTable>
        <thead class="custom white-text">
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{head}} </th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol  *ngFor="let details of new; index as i">
            <th scope="row">{{details.title}}</th>
            <td>{{details.writerName}}</td>
            <td>{{details.description}}</td>
            <td> <img [src]="details.photoUrl" width="60" height="60"></td>
            <td> <button class="btn btn-info" (click)="basicModal3.show()" style="border-radius: 10px;">أضافة</button> </td>
         
            <div mdbModal style="overflow-y: auto" #basicModal3="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" >
                <div class="modal-content">
                  <div class="modal-body" style="overflow-x: hidden !important;">
                    
                     <form #f2="ngForm" (ngSubmit)="addS(details.id)">
                    
                      <div class="md-form">
    
                      <h4 style="text-align: center; padding: 10px;">صور الرعاة</h4>
                        <div class="row" style="justify-content: center;">
                            <div class="col-md-4">
                              <div class="button-wrapper">
                                  <span class="label">
                                      الصور
                                  </span>
        <input type="file" multiple id="upload" class="upload-box" placeholder="Upload File" ngModel name="photoUrl" #photoUrl required>
                    
                    <p class="ps-warn">*كل صورة يجيب أن يكون حجمها أقل من 10 ميجابيت*</p>
                                  </div>
                            </div>
                        </div>

                        <img src="assets/imgs/loading.gif" width="165" height="120" style="margin: 0px 35.1%;" *ngIf="loading == true">
                    
                        <div class="alert alert-success" role="alert" *ngIf="done == true">
                          تم التحميل بنجاح
                        </div>
          
                         <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                          يوجد خطأ ما!الرجاء المحاولة مرة أخري
                         <br>
                          {{errorMessage}}
                        </div> 
                        
                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal3.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
          <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f2.invalid || submit == false" style="border-radius: 10px;">
                      
                      </div>
                      </div>
                     </form>
                  </div>
                </div>
              </div>
            </div>
            
            <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
             <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-body" style="text-align: center;">
                    <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                    
                    <p class="pp"> هل تريد تأكيد الحزف</p>
            
                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
                        <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="delete(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </tr>
        </tbody>
      </table>  
    </div>
    <div class="ct2"></div>
</div>

 <div mdbModal style="overflow-y: auto" #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-body" style="overflow-x: hidden !important;">
        
        <form #f="ngForm" (ngSubmit)="addNew(f)">
        
          <div class="md-form">
        
            <input mdbInput type="text" id="form1" class="form-control cc" ngModel name='title' required> 
        
            <label for="form1" class="label">عنوان الخبر </label>
        
          </div>

          <div class="md-form">
        
            <ngb-datepicker  [(ngModel)]="model" (navigate)="date = $event.next" *ngIf="calnderUI == true" [ngModelOptions]="{standalone: true}"></ngb-datepicker>

             <input mdbInput type="text" id="datem" class="form-control cc" [(ngModel)]="stringDate" name='date' required>

             <div class="date-options">
            
               <mdb-icon fas icon="calendar-day" (click)="today()"></mdb-icon>

               <mdb-icon far icon="calendar-alt" (click)="showCalanderUI()" *ngIf="on == true"></mdb-icon>
             
               <mdb-icon far icon="times-circle" (click)="hideCalnderUI()" *ngIf="off == true"></mdb-icon>               
            
              </div>
      
             <label for="datem" class="label">تاريخ الخبر</label>
        
          </div>

          <div class="md-form">
        
            <input mdbInput type="text" id="form2" class="form-control cc" ngModel name='writerName' required> 
        
            <label for="form2" class="label">أسم الكاتب </label>
        
          </div>
        
          <div class="md-form">
            
            <textarea type="text" id="form7" class="md-textarea form-control cc2" ngModel name='description' mdbInput required></textarea>
            
            <label for="form7" class="cc">المقال</label>
          
          </div>

          <div class="md-form">
            
            <textarea type="text" id="form8" class="md-textarea form-control cc2" mdbInput ngModel name='writerArticale' mdbInput required></textarea>
            
            <label for="form8" class="cc">عن الكاتب</label>
          
          </div>

          <div class="button-wrapper">

            <span class="label">
              صورة الخبر 
            </span>
            
              <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="newsImage" #newsImage required>
            
          </div>
          
          <div class="button-wrapper">

            <span class="label">
                صورة الكاتب          
            </span>
            
              <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="writerImage" #writerImage required>
            
          </div>

          <img src="assets/imgs/loading.gif" width="165" height="120" style="margin: 0px 35.1%;" *ngIf="loading == true">
                    
          <div class="alert alert-success" role="alert" *ngIf="done == true">
            تم التحميل بنجاح
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
            يوجد خطأ ما!الرجاء المحاولة مرة أخري
           <br>
            {{errorMessage}}
          </div>

          <div class="modal-footer">
            
            <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
            
            <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f.invalid || submit == false" style="border-radius: 10px;">
          
          </div>

    </form>
      </div>
    </div>
  </div>
</div> 