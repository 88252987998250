<app-get-access></app-get-access>
<app-sidebar></app-sidebar>
<div class="ct">
  <button type="button" mdbBtn class="relative waves-light custom" (click)="basicModal.show()" mdbWavesEffect>أضافة جديد</button>
<div class="container">
    <ngb-tabset>

        <ngb-tab title="المحترفين">
          <ng-template ngbTabContent>
            <table mdbTable>
                <thead class="custom white-text">
                  <tr>
                    <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr mdbTableCol  *ngFor="let details of pro; index as i">
                    <img src="assets/imgs/loading.gif" width="165" height="120" [hidden]="details.title!=='' " style="text-align: center; position: absolute;">
                    <th scope="row">{{details.title}}</th>
                    <td>{{details.date}}</td>
                    <td>{{details.age}}</td>
                    <td>{{details.period}}</td>
                    <td>{{details.coachName}}</td>
                    <td> <img [src]="details.photoUrl" width="60" height="60"></td>
                    <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
                    <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-body" style="text-align: center;">
                            <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                            
                            <p class="pp"> هل تريد تأكيد الحزف</p>
                    
                              <div class="modal-footer">
                                
                                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                                
                                <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="deleteProTrain(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>                  
                  </tr>
                </tbody>
              </table>  
        </ng-template>
        </ngb-tab>

        <ngb-tab title="المبتدأين">
          <ng-template ngbTabContent>
            <table mdbTable>
                <thead class="custom white-text">
                  <tr>
                    <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                  </tr>
                </thead>
                <tbody>
                    <tr mdbTableCol  *ngFor="let details of amature; index as i">
                        <img src="assets/imgs/loading.gif" width="165" height="120" [hidden]="details.title!=='' " style="text-align: center; position: absolute;">
                        <th scope="row">{{details.title}}</th>
                        <td>{{details.date}}</td>
                        <td>{{details.age}}</td>
                        <td>{{details.period}}</td>
                        <td>{{details.coachName}}</td>
                        <td> <img [src]="details.photoUrl" width="60" height="60"></td>
                        <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
                        <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                              <div class="modal-body" style="text-align: center;">
                                <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                                
                                <p class="pp"> هل تريد تأكيد الحزف</p>
                        
                                  <div class="modal-footer">
                                    
                                    <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                                    
                                    <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="deleteAmatureTrain(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>                      
                      </tr>
                </tbody>
              </table>  
        </ng-template>
        </ngb-tab>

        <ngb-tab title="الصغار">
            <ng-template ngbTabContent>
              <table mdbTable>
                  <thead class="custom white-text">
                    <tr>
                      <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr mdbTableCol  *ngFor="let details of young; index as i">
                        <img src="assets/imgs/loading.gif" width="165" height="120" [hidden]="details.title!=='' " style="text-align: center; position: absolute;">
                        <th scope="row">{{details.title}}</th>
                        <td>{{details.date}}</td>
                        <td>{{details.age}}</td>
                        <td>{{details.period}}</td>
                        <td>{{details.coachName}}</td>
                        <td> <img [src]="details.photoUrl" width="60" height="60"></td>
                        <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
                        <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                              <div class="modal-body" style="text-align: center;">
                                <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                                
                                <p class="pp"> هل تريد تأكيد الحزف</p>
                        
                                  <div class="modal-footer">
                                    
                                    <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                                    
                                    <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="deleteYoungTrain(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>                      
                      </tr>
                  </tbody>
                </table>  
          </ng-template>
          </ngb-tab>

          <ngb-tab title="الكبار">
            <ng-template ngbTabContent>
              <table mdbTable>
                  <thead class="custom white-text">
                    <tr>
                      <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr mdbTableCol  *ngFor="let details of old; index as i">
                        <img src="assets/imgs/loading.gif" width="165" height="120" [hidden]="details.title!=='' " style="text-align: center; position: absolute;">
                        <th scope="row">{{details.title}}</th>
                        <td>{{details.date}}</td>
                        <td>{{details.age}}</td>
                        <td>{{details.period}}</td>
                        <td>{{details.coachName}}</td>
                        <td> <img [src]="details.photoUrl" width="60" height="60"></td>
                        <td> <button class="btn btn-danger" (click)="basicModal2.show()">حذف</button></td>
                        <div mdbModal #basicModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                              <div class="modal-body" style="text-align: center;">
                                <img src="assets/imgs/trash.png" width="50" height="50" style="margin: 10px;">
                                
                                <p class="pp"> هل تريد تأكيد الحزف</p>
                        
                                  <div class="modal-footer">
                                    
                                    <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal2.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                                    
                                    <button type="button" mdbBtn class="btn btn-danger" aria-label="Close" (click)="deleteOldTrain(i)" mdbWavesEffect style="border-radius: 10px;">تأكيد</button>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>                      
                      </tr>
                  </tbody>
                </table>  
          </ng-template>
          </ngb-tab>

      </ngb-tabset>


    </div>
    <div class="ct2"></div>
</div>

<div mdbModal style="overflow-y: auto" #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-body" style="overflow-x: hidden !important;">
        
        <ngb-tabset>

            <ngb-tab title="المحترفين" class="ngb-tab">
              <ng-template ngbTabContent>
                <form #f="ngForm" (ngSubmit)="addNewPro(f)">
    
                <div class="md-form">
                
                    <input mdbInput type="text" id="title" class="form-control cc" ngModel name='title' required> 
                
                    <label for="title" class="label">عنوان الدورة </label>
                
                </div>
        
                <div class="md-form">
                
                    <input mdbInput type="text" id="period" class="form-control cc" ngModel name='period' required> 
                
                    <label for="period" class="label">المدة  </label>
                
                </div>
                
                <div class="md-form">
                
                    <input mdbInput type="text" id="price" class="form-control cc" ngModel name='price' required> 
                
                    <label for="price" class="label">السعر</label>
                
                </div>

                <div class="md-form">
                
                    <input mdbInput type="text" id="location" class="form-control cc" ngModel name='location' required> 
                
                    <label for="location" class="label">الموقع  </label>
                
                </div>

                <div class="md-form">
                
                    <input mdbInput type="number" id="age" class="form-control cc" ngModel name='age' required> 
                
                    <label for="age" class="label">السن  </label>
                
                </div>

                <div class="md-form">
        
                  <ngb-datepicker  [(ngModel)]="model" (navigate)="date = $event.next" *ngIf="calnderUI == true" [ngModelOptions]="{standalone: true}"></ngb-datepicker>
      
                   <input mdbInput type="text" id="datem" class="form-control cc" [(ngModel)]="stringDate" name='date' required>
      
                   <div class="date-options">
                  
                     <mdb-icon fas icon="calendar-day" (click)="today()"></mdb-icon>
      
                     <mdb-icon far icon="calendar-alt" (click)="showCalanderUI()" *ngIf="on == true"></mdb-icon>
                   
                     <mdb-icon far icon="times-circle" (click)="hideCalnderUI()" *ngIf="off == true"></mdb-icon>               
                  
                    </div>
            
                   <label for="datem" class="label">تاريخ البدأ</label>
              
                </div>

                <div class="md-form">
              
                  <input mdbInput type="text" id="coachName" class="form-control cc" ngModel name='coachName' required> 
              
                  <label for="coachName" class="label">اسم المدرب  </label>
              
                </div>

                <div class="md-form">
                    
                    <textarea type="text" id="coachDetails" class="md-textarea form-control cc2" ngModel name='coachDetails' mdbInput required></textarea>
                    
                    <label for="coachDetails" class="cc">تفاصيل عن المدرب</label>
                
                </div>

                <div class="md-form">
                    
                    <textarea type="text" id="otherDetails" class="md-textarea form-control cc2" ngModel name='otherDetails' mdbInput required></textarea>
                    
                    <label for="otherDetails" class="cc">تفاصيل أخري</label>
                
                </div>                
                
                <div class="button-wrapper">
        
                        <span class="label">
                            الصورة الرئيسية
                        </span>
                            
                    <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="photoUrl" #photoUrlPro required>
                            
                </div>
 
                <!-- <h4 style="text-align: center; padding: 10px;"> المنظمون</h4>
          
                <div style="padding: 10px; border-top: .2px solid #DFDBD9;">
                  <div class="md-form">
                  
                      <input mdbInput type="text" id="orgName1" class="form-control cc" ngModel name='orgName1' required> 
                  
                      <label for="orgName1" class="label"> أسم المنظم الأول</label>
                  
                    </div>
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="orgJob1" class="form-control cc" ngModel name='orgJob1' required> 
                  
                      <label for="orgJob1" class="label"> وظيفة المنظم الأول</label>
                  
                    </div>
                    <div class="button-wrapper">
          
                      <span class="label">
                          صورة المنظم 
                      </span>
                  
                      <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="orgAvatar1" #orgAvatar1Pro required>
                  
                  </div>
                </div>
          
                <div style="padding: 10px; border-top: .2px solid #DFDBD9;">
                  <div class="md-form">
                  
                      <input mdbInput type="text" id="orgName2" class="form-control cc" ngModel name='orgName2' required> 
                  
                      <label for="orgName2" class="label"> أسم المنظم الثاني</label>
                  
                    </div>
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="orgJob2" class="form-control cc" ngModel name='orgJob2' required> 
                  
                      <label for="orgJob2" class="label"> وظيفة المنظم الثاني</label>
                  
                    </div>
                    <div class="button-wrapper">
          
                      <span class="label">
                          صورة المنظم 
                      </span>
                  
                      <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="orgAvatar2" #orgAvatar2Pro required>
                  
                  </div>
                </div>
            
                <div style="padding: 10px; border-top: .2px solid #DFDBD9;">
                  <div class="md-form">
                  
                      <input mdbInput type="text" id="orgName3" class="form-control cc" ngModel name='orgName3' required> 
                  
                      <label for="orgName3" class="label"> أسم المنظم الثالث</label>
                  
                    </div>
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="orgJob3" class="form-control cc" ngModel name='orgJob3' required> 
                  
                      <label for="orgJob3" class="label"> وظيفة المنظم الثالث</label>
                  
                    </div>
                    <div class="button-wrapper">
          
                      <span class="label">
                          صورة المنظم 
                      </span>
                  
                      <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="orgAvatar3" #orgAvatar3Pro required>
                  
                  </div>
                </div> -->
                <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 35.1%;" id="loadingAlert1">
                    
                      

                <div class="alert alert-success" role="alert" style="display: none;" id="alert1">
                  تم التحميل بنجاح
                </div>

                <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                  يوجد خطأ ما!الرجاء المحاولة مرة أخري
                 <br>
                  {{errorMessage}}
                </div>

                    <div class="modal-footer">
                      
                      <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                      
                      <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f.invalid || submit == false" style="border-radius: 10px;">
                    
                    </div>
          
              </form>

              </ng-template>
            </ngb-tab>

            <ngb-tab title="المبتدأين" class="ngb-tab">
              <ng-template ngbTabContent>
                <form #f2="ngForm" (ngSubmit)="addNewAmature(f2)">
                    <div class="md-form">
                
                        <input mdbInput type="text" id="title2" class="form-control cc" ngModel name='title' required> 
                    
                        <label for="title2" class="label">عنوان الدورة </label>
                    
                    </div>
            
                    <div class="md-form">
                    
                        <input mdbInput type="text" id="period2" class="form-control cc" ngModel name='period' required> 
                    
                        <label for="period2" class="label">المدة  </label>
                    
                    </div>
                    
                    <div class="md-form">
                    
                        <input mdbInput type="text" id="price2" class="form-control cc" ngModel name='price' required> 
                    
                        <label for="price2" class="label">السعر</label>
                    
                    </div>
    
                    <div class="md-form">
                    
                        <input mdbInput type="text" id="location2" class="form-control cc" ngModel name='location' required> 
                    
                        <label for="location2" class="label">الموقع  </label>
                    
                    </div>
    
                    <div class="md-form">
                    
                        <input mdbInput type="number" id="age2" class="form-control cc" ngModel name='age' required> 
                    
                        <label for="age2" class="label">السن  </label>
                    
                    </div>
    
                    <div class="md-form">
        
                      <ngb-datepicker  [(ngModel)]="model" (navigate)="date = $event.next" *ngIf="calnderUI == true" [ngModelOptions]="{standalone: true}"></ngb-datepicker>
          
                       <input mdbInput type="text" id="datem" class="form-control cc" [(ngModel)]="stringDate" name='date' required>
          
                       <div class="date-options">
                      
                         <mdb-icon fas icon="calendar-day" (click)="today()"></mdb-icon>
          
                         <mdb-icon far icon="calendar-alt" (click)="showCalanderUI()" *ngIf="on == true"></mdb-icon>
                       
                         <mdb-icon far icon="times-circle" (click)="hideCalnderUI()" *ngIf="off == true"></mdb-icon>               
                      
                        </div>
                
                       <label for="datem" class="label">تاريخ البدأ</label>
                  
                    </div>
    
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="coachName2" class="form-control cc" ngModel name='coachName' required> 
                  
                      <label for="coachName2" class="label">اسم المدرب  </label>
                  
                    </div>
    
                    <div class="md-form">
                        
                        <textarea type="text" id="coachDetails2" class="md-textarea form-control cc2" ngModel name='coachDetails' mdbInput required></textarea>
                        
                        <label for="coachDetails2" class="cc">تفاصيل عن المدرب</label>
                    
                    </div>
    
                    <div class="md-form">
                        
                        <textarea type="text" id="otherDetails2" class="md-textarea form-control cc2" ngModel name='otherDetails' mdbInput required></textarea>
                        
                        <label for="otherDetails2" class="cc">تفاصيل أخري</label>
                    
                    </div>                
                    
                    <div class="button-wrapper">
            
                            <span class="label">
                                الصورة الرئيسية
                            </span>
                                
                        <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="photoUrlAmature" #photoUrlAmature required>
                                
                    </div>        

                    <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 35.1%;" id="loadingAlert2">
                    
                      

                    <div class="alert alert-success" role="alert" style="display: none;" id="alert2">
                      تم التحميل بنجاح
                    </div>
              
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                      يوجد خطأ ما!الرجاء المحاولة مرة أخري
                     <br>
                      {{errorMessage}}
                    </div>

                        <div class="modal-footer">
                          
                          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                          
                          <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f2.invalid || submit == false" style="border-radius: 10px;">
                        
                        </div>
              </form>
              </ng-template>
            </ngb-tab>

            <ngb-tab title="الصغار" class="ngb-tab">
                <ng-template ngbTabContent>
                  <form #f3="ngForm" (ngSubmit)="addNewYoung(f3)">
          
                      <div class="md-form">
                
                        <input mdbInput type="text" id="title2" class="form-control cc" ngModel name='title' required> 
                    
                        <label for="title2" class="label">عنوان الدورة </label>
                    
                    </div>
            
                    <div class="md-form">
                    
                        <input mdbInput type="text" id="period2" class="form-control cc" ngModel name='period' required> 
                    
                        <label for="period2" class="label">المدة  </label>
                    
                    </div>
                    
                    <div class="md-form">
                    
                        <input mdbInput type="text" id="price2" class="form-control cc" ngModel name='price' required> 
                    
                        <label for="price2" class="label">السعر</label>
                    
                    </div>
    
                    <div class="md-form">
                    
                        <input mdbInput type="text" id="location2" class="form-control cc" ngModel name='location' required> 
                    
                        <label for="location2" class="label">الموقع  </label>
                    
                    </div>
    
                    <div class="md-form">
                    
                        <input mdbInput type="number" id="age2" class="form-control cc" ngModel name='age' required> 
                    
                        <label for="age2" class="label">السن  </label>
                    
                    </div>
    
                    <div class="md-form">
        
                      <ngb-datepicker  [(ngModel)]="model" (navigate)="date = $event.next" *ngIf="calnderUI == true" [ngModelOptions]="{standalone: true}"></ngb-datepicker>
          
                       <input mdbInput type="text" id="datem" class="form-control cc" [(ngModel)]="stringDate" name='date' required>
          
                       <div class="date-options">
                      
                         <mdb-icon fas icon="calendar-day" (click)="today()"></mdb-icon>
          
                         <mdb-icon far icon="calendar-alt" (click)="showCalanderUI()" *ngIf="on == true"></mdb-icon>
                       
                         <mdb-icon far icon="times-circle" (click)="hideCalnderUI()" *ngIf="off == true"></mdb-icon>               
                      
                        </div>
                
                       <label for="datem" class="label">تاريخ البدأ</label>
                  
                    </div>
    
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="coachName2" class="form-control cc" ngModel name='coachName' required> 
                  
                      <label for="coachName2" class="label">اسم المدرب  </label>
                  
                    </div>
    
                    <div class="md-form">
                        
                        <textarea type="text" id="coachDetails2" class="md-textarea form-control cc2" ngModel name='coachDetails' mdbInput required></textarea>
                        
                        <label for="coachDetails2" class="cc">تفاصيل عن المدرب</label>
                    
                    </div>
    
                    <div class="md-form">
                        
                        <textarea type="text" id="otherDetails2" class="md-textarea form-control cc2" ngModel name='otherDetails' mdbInput required></textarea>
                        
                        <label for="otherDetails2" class="cc">تفاصيل أخري</label>
                    
                    </div>                
                    
                    <div class="button-wrapper">
            
                            <span class="label">
                                الصورة الرئيسية
                            </span>
                                
                        <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="photoUrlAmature" #photoUrlYoung required>
                                
                    </div>        

                    <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 35.1%;" id="loadingAlert3">
                    
                      

                    <div class="alert alert-success" role="alert" style="display: none;" id="alert3">
                      تم التحميل بنجاح
                    </div>
            
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                      يوجد خطأ ما!الرجاء المحاولة مرة أخري
                     <br>
                      {{errorMessage}}
                    </div>

                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
                        <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f3.invalid || submit == false" style="border-radius: 10px;">
                      
                      </div>
            
                </form>
                </ng-template>
              </ngb-tab>

              <ngb-tab title="الكبار" class="ngb-tab">
                <ng-template ngbTabContent>
                  <form #f4="ngForm" (ngSubmit)="addNewOld(f4)">
          
                      <div class="md-form">
                
                        <input mdbInput type="text" id="title2" class="form-control cc" ngModel name='title' required> 
                    
                        <label for="title2" class="label">عنوان الدورة </label>
                    
                    </div>
            
                    <div class="md-form">
                    
                        <input mdbInput type="text" id="period2" class="form-control cc" ngModel name='period' required> 
                    
                        <label for="period2" class="label">المدة  </label>
                    
                    </div>
                    
                    <div class="md-form">
                    
                        <input mdbInput type="text" id="price2" class="form-control cc" ngModel name='price' required> 
                    
                        <label for="price2" class="label">السعر</label>
                    
                    </div>
    
                    <div class="md-form">
                    
                        <input mdbInput type="text" id="location2" class="form-control cc" ngModel name='location' required> 
                    
                        <label for="location2" class="label">الموقع  </label>
                    
                    </div>
    
                    <div class="md-form">
                    
                        <input mdbInput type="number" id="age2" class="form-control cc" ngModel name='age' required> 
                    
                        <label for="age2" class="label">السن  </label>
                    
                    </div>
    
                    <div class="md-form">
        
                      <ngb-datepicker  [(ngModel)]="model" (navigate)="date = $event.next" *ngIf="calnderUI == true" [ngModelOptions]="{standalone: true}"></ngb-datepicker>
          
                       <input mdbInput type="text" id="datem" class="form-control cc" [(ngModel)]="stringDate" name='date' required>
          
                       <div class="date-options">
                      
                         <mdb-icon fas icon="calendar-day" (click)="today()"></mdb-icon>
          
                         <mdb-icon far icon="calendar-alt" (click)="showCalanderUI()" *ngIf="on == true"></mdb-icon>
                       
                         <mdb-icon far icon="times-circle" (click)="hideCalnderUI()" *ngIf="off == true"></mdb-icon>               
                      
                        </div>
                
                       <label for="datem" class="label">تاريخ البدأ</label>
                  
                    </div>
    
                    <div class="md-form">
                  
                      <input mdbInput type="text" id="coachName2" class="form-control cc" ngModel name='coachName' required> 
                  
                      <label for="coachName2" class="label">اسم المدرب  </label>
                  
                    </div>
    
                    <div class="md-form">
                        
                        <textarea type="text" id="coachDetails2" class="md-textarea form-control cc2" ngModel name='coachDetails' mdbInput required></textarea>
                        
                        <label for="coachDetails2" class="cc">تفاصيل عن المدرب</label>
                    
                    </div>
    
                    <div class="md-form">
                        
                        <textarea type="text" id="otherDetails2" class="md-textarea form-control cc2" ngModel name='otherDetails' mdbInput required></textarea>
                        
                        <label for="otherDetails2" class="cc">تفاصيل أخري</label>
                    
                    </div>                
                    
                    <div class="button-wrapper">
            
                            <span class="label">
                                الصورة الرئيسية
                            </span>
                                
                        <input type="file" id="upload" class="upload-box" placeholder="Upload File" ngModel name="photoUrlOld" #photoUrlOld required>
                                
                    </div>        

                    <img src="assets/imgs/loading.gif" width="165" height="120" style="display: none; margin: 0px 35.1%;" id="loadingAlert4">
                    
                      

                    <div class="alert alert-success" role="alert" style="display: none;" id="alert4">
                      تم التحميل بنجاح
                    </div>

                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage !=='' ">
                      يوجد خطأ ما!الرجاء المحاولة مرة أخري
                     <br>
                      {{errorMessage}}
                    </div>

                      <div class="modal-footer">
                        
                        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect style="border-radius: 10px;">غلق</button>
                        
                        <input type="submit" value="أضافة" class="btn btn-primary waves-light" [disabled]="f4.invalid || submit == false" style="border-radius: 10px;">
                      
                      </div>
            
                </form>
                </ng-template>
              </ngb-tab>

          </ngb-tabset>
      </div>
    </div>
  </div>
</div>